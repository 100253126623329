const defaultState = {
	currentModule: "",
}

const SET_CURRENT_MODULE = "SET_CURRENT_MODULE";

export const tempData = (state = defaultState, action) => {
	switch(action.type) {
		case SET_CURRENT_MODULE: {
			return {...state, currentModule: action.payload}
		}

		default:
			return state;
	}
}

// export const asyncGetServerList = () => 
// 	function (dispatch) {
// 		let query = "api/server-side/api/source/GetServerList.php";
// 		fetch(query)
// 			.then(response => response.json())
// 			.then(result => {
// 				if(result.hasOwnProperty("error") === false)
// 					dispatch(getServerList(result));
// 			});
// 	}

export const setCurrentModule = (payload) => ({type: SET_CURRENT_MODULE, payload});