import React, { useEffect, useRef, useState } from 'react';
import './LogsModule.scss';
import { socketIO } from '../..';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import { CalendarModule } from '../../utils/CalendarModule';
import HashMap from 'hashmap';

import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';

export const AdminLogsPageModule = () => {

    const [ActiveButton, SetActiveButton] = useState(1);
    const [SearchInput, SetSearchInput] = useState("");
    const [ReasonSearchInput, SetReasonSearchInput] = useState("");
    const [AdminData, SetAdminData] = useState({});
	const [sidCollection, SetSidCollection] = useState(new HashMap());

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    let firstItemIndex = 0;
    const [isChatLoading, setIsChatLoading] = useState(true);
	const [LoadSteamData, setLoadSteamData] = useState(false);

    const lastElement = useRef();
    const firstLoad = useRef();
	const lastid = useRef();
    const observer = useRef();

    const [calendarModalActive, setCalendarModalActive] = useState(false);
    const calendarRefValue = useRef();

    const selectedServer = useSelector(state => state.servers);

    const [tableColumnSort, setTableColumnSort] = useState("");
    const [updownSort, setUpdownSort] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        firstLoad.current = true;

        calendarRefValue.current = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)];
    }, []);

	const activeButtonOneFunction = (data) => {
		if(ActiveButton !== 1) return;

		let res = JSON.parse(data);
		if(!res.hasOwnProperty("error"))
		{
			setLoadSteamData(true);

			const filtered_data = customSort(Object.entries(res)).slice(0, limit);
			let sid_collection = [];

			filtered_data.forEach((filtered_data_value, index) => {
				if(sidCollection.has(filtered_data_value[1].auth) === false)
					sid_collection.push(filtered_data_value[1].auth);
			});

			if(sid_collection.length !== 0)
			{
				socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
					sid_info = JSON.parse(sid_info);
					if(sid_info.success !== 200)
					{
						console.log("Error get SteamID's info from VisitLogsModule");
						return;
					}

					const hash_sids = new HashMap(sid_info.steam_data);

					let sidCollectionClone = sidCollection.clone();

					hash_sids.forEach((kvalue, key) => {
						sidCollectionClone.set(key, kvalue);
					});

					SetAdminData(res);
					SetSidCollection(sidCollectionClone);

					setLoadSteamData(false);
				});
			} else {
				SetAdminData(res);
				setLoadSteamData(false);
			}
		} else {
			// SetAdminData({});
			setLoadSteamData(false);
			// console.log(res.error);
		}
	};

	const activeButtonTwoFunction = (data) => {
		if(ActiveButton !== 2) return;

		let res = JSON.parse(data);
		if(!res.hasOwnProperty("error"))
		{
			setLoadSteamData(true);

			if(res[Object.keys(res).length - 1] !== undefined)
			{
				lastid.current = res[Object.keys(res).length - 1].id;
			}

			// console.log(res);

			const filtered_data = Object.entries(res);
			let sid_collection = [];

			filtered_data.forEach((filtered_data_value, index) => {
				if(sidCollection.has(filtered_data_value[1].auth) === false)
					sid_collection.push(filtered_data_value[1].auth);
			});

			if(sid_collection.length !== 0)
			{
				socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
					sid_info = JSON.parse(sid_info);
					if(sid_info.success !== 200)
					{
						console.log("Error get SteamID's info from VisitLogsModule");
						return;
					}

					const hash_sids = new HashMap(sid_info.steam_data);

					let sidCollectionClone = sidCollection.clone();

					hash_sids.forEach((kvalue, key) => {
						sidCollectionClone.set(key, kvalue);
					});

					SetAdminData(prevAdminLogsData => {
						return Object.assign({}, Object.values(prevAdminLogsData).concat(Object.values(res)));
					});
					SetSidCollection(sidCollectionClone);

					setLoadSteamData(false);
				});
			} else {
				SetAdminData(prevAdminLogsData => {
					return Object.assign({}, Object.values(prevAdminLogsData).concat(Object.values(res)));
				});
				setLoadSteamData(false);
			}
		} else {
			// SetAdminData({});
			setLoadSteamData(false);
			// console.log(res.error);
		}
	};

	const activeButtonThreeFunction = (data) => {
		if(ActiveButton !== 3) return;

		let res = JSON.parse(data);
		if(!res.hasOwnProperty("error"))
		{
			setLoadSteamData(true);

			if(res[Object.keys(res).length - 1] !== undefined)
			{
				lastid.current = res[Object.keys(res).length - 1].id;
			}

			const filtered_data = Object.entries(res);
			let sid_collection = [];

			filtered_data.forEach((filtered_data_value, index) => {
				if
				(
					filtered_data_value[1].auth_admin > 0 && 
					sidCollection.has(filtered_data_value[1].auth_admin) === false
				)
					sid_collection.push(filtered_data_value[1].auth_admin);

				if
				(
					filtered_data_value[1].auth_target > 0 && 
					sidCollection.has(filtered_data_value[1].auth_target) === false
				)
					sid_collection.push(filtered_data_value[1].auth_target);
			});

			if(sid_collection.length !== 0)
			{
				socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
					sid_info = JSON.parse(sid_info);
					if(sid_info.success !== 200)
					{
						console.log("Error get SteamID's info from VisitLogsModule");
						return;
					}

					const hash_sids = new HashMap(sid_info.steam_data);

					let sidCollectionClone = sidCollection.clone();

					hash_sids.forEach((kvalue, key) => {
						sidCollectionClone.set(key, kvalue);
					});

					SetAdminData(prevAdminLogsData => {
						return Object.assign({}, Object.values(prevAdminLogsData).concat(Object.values(res)));
					});
					SetSidCollection(sidCollectionClone);

					setLoadSteamData(false);
				});
			} else {
				SetAdminData(prevAdminLogsData => {
					return Object.assign({}, Object.values(prevAdminLogsData).concat(Object.values(res)));
				});
				setLoadSteamData(false);
			}
		} else {
			// SetAdminData({});
			setLoadSteamData(false);
			// console.log(res.error);
		}
	};

	// useEffect(() => setPage(1), [selectedServer.selectedServer, ActiveButton]);

    useEffect(() => {
        if(ActiveButton === 1)
		{
            socketIO.emit("GetAdminPlayedTime", JSON.stringify({
				"server_id": selectedServer.selectedServer,
				"date_start": calendarRefValue.current[0].toISOString().slice(0, 10),
				"date_end": calendarRefValue.current[1].toISOString().slice(0, 10)
			}), activeButtonOneFunction);
        }
    }, [ActiveButton, selectedServer.selectedServer, calendarRefValue.current]);

	useEffect(() => {
		// if(ActiveButton !== 1)
		// {
			lastid.current = 0;
			SetAdminData({});
			setPage(1);
		// }
	}, [ActiveButton, selectedServer.selectedServer, calendarRefValue.current]);

	useEffect(() => {
		const timeOutHandler = setTimeout(() => {
			if(ActiveButton === 1)
			{
				setLoadSteamData(true);

				const filtered_data = customSort(Object.entries(AdminData)).slice(limit * (page - 1), limit * page);
				
				let sid_collection = [];

				filtered_data.forEach((filtered_data_value, index) => {
					if(sidCollection.has(filtered_data_value[1].auth) === false)
						sid_collection.push(filtered_data_value[1].auth);
				});

				if(sid_collection.length !== 0)
				{
					socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
						if(ActiveButton !== 1)
						{
							setLoadSteamData(false);
							return;
						}

						sid_info = JSON.parse(sid_info);
						if(sid_info.success !== 200)
						{
							console.log("Error get SteamID's info from VisitLogsModule");
							return;
						}

						const hash_sids = new HashMap(sid_info.steam_data);

						let sidCollectionClone = sidCollection.clone();

						hash_sids.forEach((kvalue, key) => {
							sidCollectionClone.set(key, kvalue);
						});

						SetSidCollection(sidCollectionClone);

						setLoadSteamData(false);
					});
				} else {
					setLoadSteamData(false);
				}
			}
		}, 100);

		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, AdminData, SearchInput, calendarRefValue.current]);

	useEffect(() => {
		const timeOutHandler = setTimeout(() => {
			
			if(ActiveButton === 2)
			{
				socketIO.emit("GetAdminConnectionLogs", JSON.stringify({
					"server_id": selectedServer.selectedServer,
					"get": limit,
					"lastid": lastid.current,
					"search": SearchInput,
					"date_start": Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000),
					"date_end": Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)
				}), activeButtonTwoFunction);
			} else if(ActiveButton === 3)
			{
				socketIO.emit("GetAdminActionLogs", JSON.stringify({
					"server_id": selectedServer.selectedServer,
					"get": limit,
					"lastid": lastid.current,
					"search": SearchInput,
					"date_start": Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000),
					"date_end": Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)
				}), activeButtonThreeFunction);
			}
		}, 250);

		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, SearchInput, selectedServer.selectedServer, ActiveButton, calendarRefValue.current]);

    useEffect(() => {
        if(isChatLoading || lastElement.current == null  || LoadSteamData) return;

        if(observer.current) {
            observer.current.disconnect();
        }
        
        var callback = function(entries, observer) {
            if(entries[0].isIntersecting && (limit * page) < (customSort(Object.entries(AdminData)).length + limit)) {
                setPage(page + 1);
            }
            firstLoad.current = false;
        };
        
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current);
    }, [isChatLoading, AdminData, LoadSteamData]);

	useEffect(() => {
        setIsChatLoading(true);

		const timeOutHandler = setTimeout(() =>	setIsChatLoading(false), 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, calendarRefValue.current]);
    
    useEffect(() => {
        const timeOutHandler = setTimeout(() => {

			if(ActiveButton !== 1)
			{
				lastid.current = 0;
				SetAdminData({});
			}

			if(page !== 1)
			{
				setPage(1);
			}
		}, 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};

    }, [SearchInput]);

    // const customSortAdmins = (arr) => {
    //     if(arr === null) return [];
        
    //     arr = arr.filter(element => (element.admin !== undefined && element.admin === 1));

    //     return [].concat(arr);
    // }

    function updownSortChange() {
        if(updownSort) {
            setUpdownSort(false);
        } else {
            setUpdownSort(true);
        }
    }

    const customSort = (arr) => {
        if(arr === null) return [];

        if(ActiveButton === 1) {
            arr = arr.filter(element => ((sidCollection.get(element[1].auth) !== undefined && sidCollection.get(element[1].auth).personaname.toLowerCase().includes(SearchInput.toLowerCase())) || 
                                        (element[1].steamidText !== undefined && element[1].steamidText.toLowerCase().includes(SearchInput.toLowerCase()))));

            return [].concat(arr).sort((a, b) => parseFloat(b[1].date) - parseFloat(a[1].date));
        } else if(ActiveButton === 2) {
			//	((sidCollection.get(element[1].auth) !== undefined && sidCollection.get(element[1].auth).personaname.toLowerCase().includes(SearchInput.toLowerCase())) || 
			//	(element[1].steamidText !== undefined && element[1].steamidText.toLowerCase().includes(SearchInput.toLowerCase()))) &&
            // arr = arr.filter(element => (Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000) <= element[1].timestamp && 
            //                             element[1].timestamp <= Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)));

            if(tableColumnSort == "time-admlogin" && updownSort) {
                return [].concat(arr).sort((a, b) => parseFloat(a[1].timestamp) - parseFloat(b[1].timestamp));
            } else if(tableColumnSort == "time-admlogin" && !updownSort) {
                return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
            }
            return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        } else if(ActiveButton === 3) {
			// (((sidCollection.get(element[1].auth_admin) !== undefined && sidCollection.get(element[1].auth_admin).personaname.toLowerCase().includes(SearchInput.toLowerCase())) || 
            //                             (element[1].steamidTextAdmin !== undefined && element[1].steamidTextAdmin.toLowerCase().includes(SearchInput.toLowerCase())) ||
            //                             (element[1].nick_admin !== undefined && element[1].nick_admin.toLowerCase().includes(SearchInput.toLowerCase())) ||
            //                             (element[1].nick_target !== undefined && element[1].nick_target.toLowerCase().includes(SearchInput.toLowerCase())) ||
            //                             (sidCollection.get(element[1].auth_target) !== undefined && sidCollection.get(element[1].auth_target).personaname.toLowerCase().includes(SearchInput.toLowerCase())) || 
            //                             (element[1].steamidTextTarget !== undefined && element[1].steamidTextTarget.toLowerCase().includes(SearchInput.toLowerCase()))) &&
            //                             (element[1].move !== undefined && element[1].move.toLowerCase().includes(ReasonSearchInput.toLowerCase()))) &&

            // arr = arr.filter(element => (
			// 							Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000) <= element[1].timestamp && 
            //                             element[1].timestamp <= Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)));

            if(tableColumnSort == "time-admaction" && updownSort) {
                return [].concat(arr).sort((a, b) => parseFloat(a[1].timestamp) - parseFloat(b[1].timestamp));
            } else if(tableColumnSort == "time-admaction" && !updownSort) {
                return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
            }
            return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        }
        
        //return [].concat(arr);
    }

    function seconds2time(seconds) {
		var days  = Math.floor(seconds / 86400);
		seconds  -= days*86400;
        var hrs   = Math.floor(seconds / 3600);
        seconds  -= hrs * 3600;
        var mnts = Math.floor(seconds / 60);
        seconds  -= mnts * 60;

		var res = '';

		if(days > 0)
		{
			res = res + days + " д. ";
		}

		if(hrs > 0)
		{
			res = res + hrs + " ч. ";
		}

		if(mnts > 0)
		{
			res = res + mnts + " м. ";
		}

		if(seconds > 0)
		{
			res = res + seconds + " с.";
		}

        return res;
    }

	function seconds2timeWDays(seconds) {
        var hrs   = Math.floor(seconds / 3600);
        seconds  -= hrs * 3600;
        var mnts = Math.floor(seconds / 60);
        seconds  -= mnts * 60;
        return (hrs + " ч. " + mnts + " м. " + seconds + " с.");
    }

    return (
        <>
        {auth && auth.permission.indexOf("k") !== -1 &&
            <div className='admin-log-block text-align-center'>
                {calendarRefValue.current &&
                        <input id='calendar' onClick={() => {setCalendarModalActive(true);}} value={calendarRefValue.current[0].toLocaleDateString() + " - " + calendarRefValue.current[1].toLocaleDateString()} readOnly></input>
                    }
                <div className='admin-utils'>
                    <input className='search-input' onChange={(e) => {SetSearchInput(e.target.value)}} value={SearchInput} placeholder='Steam ID или ник игрока'></input>
                    <input className='search-input' onChange={(e) => {SetReasonSearchInput(e.target.value)}} value={ReasonSearchInput} placeholder='Действие'></input>
                    
					{auth.permission.indexOf("p") !== -1 &&
						<button disabled={Object.keys(AdminData).length === 0} className='grey-button' onClick={() => {if(ActiveButton !== 1) {SetAdminData({}); SetActiveButton(1);}}}>Онлайн админов</button>
					}

					{auth.permission.indexOf("q") !== -1 &&
						<button disabled={Object.keys(AdminData).length === 0} className='grey-button' onClick={() => {if(ActiveButton !== 2) {SetAdminData({}); SetActiveButton(2);}}}>Вход/Выход админов</button>
					}

					{auth.permission.indexOf("r") !== -1 &&
						<button disabled={Object.keys(AdminData).length === 0} className='grey-button' onClick={() => {if(ActiveButton !== 3) {SetAdminData({}); SetActiveButton(3);}}}>Действия админов</button>
					}
                    <span className='count-columns'>Кол-во записей: {customSort(Object.entries(AdminData)).length}</span>
                </div>
                <table>
                    <thead>
                        {ActiveButton === 1 &&
                            <tr>
                                <th></th>
                                <th id='name-admonline'>Имя</th>
                                <th id='stmid-admonline'>Steam ID</th>
                                {/* <th id='date-admonline'>Дата</th> */}
                                <th id='time-admonline'>Время онлайн</th>
                            </tr>
                        }
                        {ActiveButton === 2 &&
                            <tr>
                                <th></th>
                                <th id='name-admlogin'>Имя</th>
                                <th id='stmid-admlogin'>Steam ID</th>
                                <th id='time-admlogin' onClick={(e) => {setTableColumnSort(e.currentTarget.id); updownSortChange();}} style={{cursor: "pointer"}}>Время</th>
                                <th id='login-admlogin'>Вход/Выход</th>
                                <th id='login-admlogin'>Сервер</th>
                            </tr>
                        }
                        {ActiveButton === 3 &&
                            <tr>
                                <th id='nameadm-admaction'>Имя админа</th>
                                {/* <th id='stmidadm-admaction'>Steam ID админа</th> */}
                                <th id='nametrgt-admaction'>Имя цели</th>
                                {/* <th id='stmidtrgt-admaction'>Steam ID цели</th> */}
                                <th id='action-admaction'>Действие</th>
                                <th id='reason-admaction'>Причина</th>
                                <th id='time-admaction' onClick={(e) => {setTableColumnSort(e.currentTarget.id); updownSortChange();}} style={{cursor: "pointer"}}>Время</th>
                                <th id='time-admaction'>Длительность</th>
                                <th id='time-admaction'>Сервер</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {ActiveButton === 1 &&
                            customSort(Object.entries(AdminData)).slice(firstItemIndex, limit * page).map((data, index) => { return (
                                <tr key={index}>
									{sidCollection.has(data[1].auth) !== false &&
									<>
										<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth}><img src={sidCollection.get(data[1].auth).avatarfull} alt='Avatar'/></Link></td>
										<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth}>{sidCollection.get(data[1].auth).personaname}</Link></td>
										<td>{data[1].steamidText}</td>
										{/* <td>{new Date(data[1].date).toLocaleString().slice(0, -10)}</td> */}
										<td>{seconds2timeWDays(data[1].played_time)}</td>
									</>}
                                </tr>
                            )})
                        }
                        {ActiveButton === 2 &&
                            customSort(Object.entries(AdminData)).map((data, index) => { return (
                                <tr key={index}>
									{sidCollection.has(data[1].auth) !== false &&
									<>
									{/* {sidCollection.get(data[1].auth).personaname} */}
										<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth}><img src={sidCollection.get(data[1].auth).avatarfull} alt='Avatar'/></Link></td>
										<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth}>{data[1].nickname}</Link></td>
										<td>{data[1].steamidText}</td>
										<td>{new Date(data[1].timestamp * 1000).toLocaleString()}</td>
										<td style={data[1].is_connect ? ({color: "green"}) : ({color: "red"})}>{data[1].is_connect ? ("Вход") : ("Выход")}</td>
										{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
											<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
											:
											<td>Сервер не найден</td>
										}
									</>}
								</tr>
                            )})
                        }
                        {ActiveButton === 3 &&
                            customSort(Object.entries(AdminData)).map((data, index) => { return (
                                <tr key={index}>
									<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth_admin}>{sidCollection.get(data[1].auth_admin) !== undefined ? 
													sidCollection.get(data[1].auth_admin).personaname : data[1].nick_admin}</Link>
									</td>
									{/* <td>{data[1].steamidTextAdmin}</td> */}
									<td>
										{
											data[1].auth_target !== -1 && sidCollection.get(data[1].auth_target) !== undefined ?
												<Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth_target}>{sidCollection.get(data[1].auth_target).personaname}</Link> : data[1].nick_target
										}
									</td>
									{/* <td>
										{
											data[1].auth_target !== -1 ?
												data[1].steamidTextTarget : "Неизвестный SteamID"
										}
									</td> */}
									<td>{data[1].move}</td>
									<td>{data[1].reason}</td>
									<td>{new Date(data[1].timestamp * 1000).toLocaleString()}</td>
									<td style={{color: "#CE9178"}}>
										{
											data[1].duration > 0 && seconds2time(data[1].duration)
											|| data[1].duration === 0 && "Навсегда"
											|| data[1].duration === -1 && "Без срока"
										}
									</td>
									{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
										<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
										:
										<td>Сервер не найден</td>
									}
                                </tr>
                            )})
                        }
                    </tbody>
                </table>
                {(!isChatLoading && !LoadSteamData && AdminData) ?
                (<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
                (
                    <ContentLoader
                        viewBox="0 0 400 160"
                        height={"100%"}
                        width={"100%"}
                        backgroundColor="transparent"
                    >
                        <circle cx="150" cy="86" r="5" />
                        <circle cx="194" cy="86" r="5" />
                        <circle cx="238" cy="86" r="5" />
                    </ContentLoader>
                )}
            </div>}

            {/* {calendarModalActive && (
                <div className='' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <Calendar onChange={(dates) => {calendarRefValue.current = dates;}} returnValue={"range"} selectRange={true} />
                    </div>
                </div>
            )} */}
			{calendarModalActive && (
                <div className='admin-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <CalendarModule setDateInfo={(dates) => calendarRefValue.current = dates} date={
							[
								{
								  startDate: calendarRefValue.current[0],
								  endDate: calendarRefValue.current[1],
								  key: 'selection'
								}
							]
						} />
                    </div>
                </div>
            )}
        </>
    );
};
