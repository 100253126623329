import React from 'react';
import './LogsModule.scss';
import {createRippleEffect} from '../../utils/RippleEffect.js';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LogsPageModule = () => {

    const auth = useSelector(state => state.auth);

    return (
        <>
            {auth && 
            <>
            {auth.permission.indexOf("i") !== -1 &&
                <div className='log-block text-align-center'>
                    <span>Лог посещений</span>

                    <Link onClick={(e) => {createRippleEffect(e);}} className='green-button pos-rel' to={"/VisitLogsModule"}>Открыть</Link>
                </div>
            }
            {auth.permission.indexOf("k") !== -1 &&
                <div className='log-block text-align-center'>
                    <span>Админ логи</span>

                    <Link onClick={(e) => {createRippleEffect(e);}} className='green-button pos-rel' to={"/AdminLogsPageModule"}>Открыть</Link>
                </div>
            }
            {auth.permission.indexOf("d") !== -1 &&
                <div className='log-block text-align-center'>
                    <span>Чат логи</span>

                    <Link onClick={(e) => {createRippleEffect(e);}} className='green-button pos-rel' to={"/ChatLogsPageModule"}>Открыть</Link>
                </div>
            }
            {auth.permission.indexOf("l") !== -1 &&
                <div className='log-block text-align-center'>
                    <span>Список игроков</span>

                    <Link onClick={(e) => {createRippleEffect(e);}} className='green-button pos-rel' to={"/AllPlayersLogsPageModule"}>Открыть</Link>
                </div>
            }
            </>
            }
        </>
    );
};
