import React, { useEffect, useState } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfWeek,
	endOfWeek
} from 'date-fns';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const CalendarModule = ({ setDateInfo, date }) => {

	const defineds = {
		startOfWeek: startOfWeek(new Date()),
		endOfWeek: endOfWeek(new Date()),
		startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
		endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
		startOfToday: startOfDay(new Date()),
		endOfToday: endOfDay(new Date()),
		startOfYesterday: startOfDay(addDays(new Date(), -1)),
		endOfYesterday: endOfDay(addDays(new Date(), -1)),
		startOfMonth: startOfMonth(new Date()),
		endOfMonth: endOfMonth(new Date()),
		startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
		endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	};

	const srange = createStaticRanges([
		{
		  label: 'Сегодня',
		  range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		  }),
		},

		{
		  label: 'Вчера',
		  range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		  }),
		},
	  
		{
		  label: 'Текущая неделя',
		  range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		  }),
		},

		{
		  label: 'Предыдущая неделя',
		  range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		  }),
		},

		{
		  label: 'Текущий месяц',
		  range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		  }),
		},

		{
		  label: 'Предыдущий месяц',
		  range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		  }),
		},
	]);

	const [dateRange, setDateRange] = useState(date);

	function setDateSelection(selection)
	{
		setDateInfo([selection.startDate, addDays(selection.endDate, 1)]);
		setDateRange([
			{
				startDate: selection.startDate,
				endDate: selection.endDate,
				key: selection.key
			}
		]);
	}
    
    return (
        <>
            <DateRangePicker
				onChange={item => setDateSelection(item.selection)}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				months={2}
				ranges={dateRange}
				direction="horizontal"
				locale={locales['ru']}
				inputRanges={[]}
				staticRanges={srange}
			/>
        </>
    );
};
