import React, { useEffect, useRef, useState } from 'react';
import './ProfileModule.scss';
import {createRippleEffect} from '../../utils/RippleEffect.js';
import { Link, useSearchParams } from 'react-router-dom';
import { BsSteam } from 'react-icons/bs';
import { socketIO } from '../..';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { CalendarModule } from '../../utils/CalendarModule';
import axios from 'axios';
import HashMap from 'hashmap';


import 'react-calendar/dist/Calendar.css';

export const ProfilePageModule = () => {
    const [modalActive, setModalActive] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const steamid = searchParams.get("steamid");
    const steamidRef = useRef();
    const [userProfile, setUserProfile] = useState(null);
    const [adminProfile, setAdminProfile] = useState(null);
    const auth = useSelector(state => state.auth);
    const [reasonInput, setReasonInput] = useState("");
    const [warnLengthInput, setWarnLengthInput] = useState("");
    const [warnList, setWarnList] = useState(null);
    const [profileNotFound, setProfileNotFound] = useState(false);

    const selectedServer = useSelector(state => state.servers);

    useEffect(() => {
        socketIO.emit("GetAdminProfile", JSON.stringify({"steamid": steamid, "server_id": selectedServer.selectedServer}), (data) => {
			if(JSON.parse(data).error === undefined) {
            	setAdminProfile(JSON.parse(data));
            }
			else {
				// console.log(JSON.parse(data).error);
            }
        });
    }, [searchParams, selectedServer.selectedServer]);

    useEffect(() => {
        steamidRef.current = searchParams.get("steamid");

        // socketIO.emit("GetProfileData", JSON.stringify({"steamid": steamid, "server_id": selectedServer.selectedServer}), (data) => {
        //     setProfileNotFound(false);

		// 	if(JSON.parse(data).error === undefined) {
        //     	setUserProfile(JSON.parse(data));
        //         // console.log(JSON.parse(data));
        //     }
		// 	else {
        //         setProfileNotFound(true);
		// 		// console.log(JSON.parse(data).error);
        //     }
        // });
    }, [searchParams]);

    useEffect(() => {
        socketIO.emit("GetProfileData", JSON.stringify({"steamid": steamid, "server_id": selectedServer.selectedServer}), (data) => {
            setProfileNotFound(false);

			if(JSON.parse(data).error === undefined) {
            	setUserProfile(JSON.parse(data));
				// console.log(JSON.parse(data))
            } else {
                setProfileNotFound(true);
				console.log(JSON.parse(data).error);
            }
        });
    }, [searchParams, selectedServer.selectedServer]);

    useEffect(() => {
        socketIO.emit("GetWarnList", JSON.stringify({"steamid": steamid, "server_id": selectedServer.selectedServer}), (data) => {
			if(JSON.parse(data).error === undefined) {
            	setWarnList(JSON.parse(data));
            }
			else {
				// console.log(JSON.parse(data).error);
            }
        });
    }, [searchParams, selectedServer.selectedServer]);

    function incWarn() {
        socketIO.emit("AddAdminWarn", JSON.stringify({"steamid": userProfile.profile.steaminfo.steamid, "server_id": selectedServer.selectedServer, "reason": reasonInput, "timestamp": (new Date() / 1000), "warn_end": (parseInt(new Date().getTime() / 1000) + parseInt(warnLengthInput*60))}), (data) => {
            if(JSON.parse(data).status === 200) {
                setWarnList(JSON.parse(data).data);

                setAdminProfile(profile => {
					profile.warns += 1;
					return profile;
				});
            } else {
                console.log(JSON.parse(data).error);
                if(JSON.parse(data).status === 400) {
                    setAdminProfile(profile => {
						profile.warns += 1;
						return profile;
					});
                    setWarnList(null);
                }
            }
        });
    }

    function decWarn(id) {
        socketIO.emit("RemoveAdminWarn", JSON.stringify({"steamid": userProfile.profile.steaminfo.steamid, "id": id, "server_id": selectedServer.selectedServer}), (data) => {
            if(JSON.parse(data).status === 200) {
                setWarnList(JSON.parse(data).data);

                // let profile = JSON.parse(JSON.stringify(adminProfile));
                // profile.warns -= 1;
                setAdminProfile(profile => {
					profile.warns -= 1;
					return profile;
				});
            } else {
                console.log(JSON.parse(data).error);
                if(JSON.parse(data).status === 400) {
                    setAdminProfile(profile => {
						profile.warns -= 1;
						return profile;
					});
                    setWarnList(null);
                }
            }
        });
    }

    //-------------------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //ChatLogs
    const [SearchInput, SetSearchInput] = useState("");
    const [ChatLogsData, SetChatLogsData] = useState({});
	const [sidCollection, SetSidCollection] = useState(new HashMap());
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    let firstItemIndex = 0;
    const [isChatLoading, setIsChatLoading] = useState(true);
    const [LoadSteamData, setLoadSteamData] = useState(false);

    const lastElement = useRef();
    const firstLoad = useRef();
    const observer = useRef();

    const [calendarModalActive, setCalendarModalActive] = useState(false);
    const calendarRefValue = useRef();

    useEffect(() => {
        firstLoad.current = true;

        calendarRefValue.current = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)];

        // socketIO.emit("GetChatLogs", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
        //     SetChatLogsData(JSON.parse(data));

        // });
    }, []);

	// optimize
    useEffect(() => {
        socketIO.emit("GetUserChatLogs", JSON.stringify(
			{
				"server_id": selectedServer.selectedServer,
				"steamid": steamidRef.current,
				"date_start": Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000),
				"date_end": Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)
			}), (data) => {
            let res = JSON.parse(data);
			if(!res.hasOwnProperty("error"));
			{
				// Формирование массива sid_collection который содержит steamid, для которых необходимо получить информацию
				setLoadSteamData(true);

				const filtered_data = customSortCount(Object.entries(res), steamidRef.current).slice(0, limit);
				let sid_collection = [];

				filtered_data.forEach((filtered_data_value, index) => {
					if(sidCollection.has(filtered_data_value[1].auth) === false)
						sid_collection.push(filtered_data_value[1].auth);
				});

				if(sid_collection.length !== 0)
				{
					socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
						sid_info = JSON.parse(sid_info);
						if(sid_info.success !== 200)
						{
							console.log("Error get SteamID's info from ProfilePageModule");
							return;
						}

						const hash_sids = new HashMap(sid_info.steam_data);

						let sidCollectionClone = sidCollection.clone();

						hash_sids.forEach((kvalue, key) => {
							sidCollectionClone.set(key, kvalue);
						});

						SetSidCollection(sidCollectionClone);
						SetChatLogsData(res);

						setLoadSteamData(false);
					});
				} else {
					SetChatLogsData(res);
					setLoadSteamData(false);
				}
			}
        });
    }, [selectedServer.selectedServer, calendarRefValue.current]);

	useEffect(() => {
		const timeOutHandler = setTimeout(() => {
			setLoadSteamData(true);

			const filtered_data = customSortCount(Object.entries(ChatLogsData), steamidRef.current).slice(limit * (page - 1), limit * page);
			let sid_collection = [];

			filtered_data.forEach((filtered_data_value, index) => {
				if(sidCollection.has(filtered_data_value[1].auth) === false)
					sid_collection.push(filtered_data_value[1].auth);
			});

			if(sid_collection.length !== 0)
			{
				socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
					sid_info = JSON.parse(sid_info);
					if(sid_info.success !== 200)
					{
						console.log("Error get SteamID's info from ProfilePageModule");
						return;
					}

					const hash_sids = new HashMap(sid_info.steam_data);

					let sidCollectionClone = sidCollection.clone();

					hash_sids.forEach((kvalue, key) => {
						sidCollectionClone.set(key, kvalue);
					});

					SetSidCollection(sidCollectionClone);

					setLoadSteamData(false);
				});
			} else {
				setLoadSteamData(false);
			}
		}, 250);

		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, ChatLogsData, SearchInput, calendarRefValue.current]);

    useEffect(() => {
        if(isChatLoading || lastElement.current === null || lastElement.current === undefined || LoadSteamData) { return; }

        if(observer.current) {
            observer.current.disconnect();
        }
        
        var callback = function(entries, observer) {
            if(entries[0].isIntersecting && (limit * page) < (customSortCount(Object.entries(ChatLogsData), steamidRef.current).length + limit)) {
                setPage(page + 1);
            }
            firstLoad.current = false;
        };
        
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current);
    }, [isChatLoading, ChatLogsData, LoadSteamData]);

	useEffect(() => {
        setIsChatLoading(true);

		const timeOutHandler = setTimeout(() =>	setIsChatLoading(false), 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, calendarRefValue.current]);
    
    useEffect(() => {
        const timeOutHandler = setTimeout(() => page !== 1 && setPage(1), 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};

    }, [SearchInput]);

    const customSort = (arr, ur) => {
        if(arr === null || ur === null || ur === undefined) return [];

        arr = arr.filter(element => ((element[1].nickname !== undefined && element[1].nickname.toLowerCase().includes(SearchInput.toLowerCase())) || 
                                    (element[1].steamid !== undefined && element[1].steamid.toLowerCase().includes(SearchInput.toLowerCase())) ||
                                    (element[1].text !== undefined && element[1].text.toLowerCase().includes(SearchInput.toLowerCase()))) &&
                                    (element[1].auth !== undefined && element[1].auth.toLowerCase().includes(ur.profile.auth))
                                    // (Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000) <= element[1].timestamp && 
                                    // element[1].timestamp <= Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000))
									);

        return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        //return [].concat(arr);
    }

    const customSortCount = (arr, ur) => {
        if(arr === null || ur === null || ur === undefined) return [];

        arr = arr.filter(element => ((element[1].nickname !== undefined && element[1].nickname.toLowerCase().includes(SearchInput.toLowerCase())) || 
                                    (element[1].steamid !== undefined && element[1].steamid.toLowerCase().includes(SearchInput.toLowerCase())) ||
                                    (element[1].text !== undefined && element[1].text.toLowerCase().includes(SearchInput.toLowerCase()))) &&
                                    (element[1].auth !== undefined && element[1].auth.toLowerCase().includes(ur))
                                    // (Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000) <= element[1].timestamp && 
                                    // element[1].timestamp <= Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000))
									);

        return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        //return [].concat(arr);
    }

    function seconds2time(seconds) {
		var days  = Math.floor(seconds / 86400);
		seconds  -= days*86400;
        var hrs   = Math.floor(seconds / 3600);
        seconds  -= hrs * 3600;
        var mnts = Math.floor(seconds / 60);
        seconds  -= mnts * 60;
        return (days + " д. " + hrs + " ч. " + mnts + " м. " + seconds + " с.");
    }
    //ChatLogs

    //-------------------------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------------------------------------------------

    //IpCoincidenceLogs
    const [ipCoincidenceLogsData, setIpCoincidenceLogsData] = useState(null);
    const [ipHistoryLogsData, setIpHistoryLogsData] = useState(null);
    const [typeLogs, setTypeLogs] = useState("chatlog");

    useEffect(() => {
        if(userProfile === null) return;

        socketIO.emit("GetIPConfictList", JSON.stringify({"ip": userProfile.profile.ip, "server_id": selectedServer.selectedServer}), (data) => {
			if(JSON.parse(data).error === undefined) {
            	setIpCoincidenceLogsData(JSON.parse(data));
            }
			else {
				console.log(JSON.parse(data).error);
            }
        });

        socketIO.emit("GetIPHistory", JSON.stringify({"steamid": steamid, "server_id": selectedServer.selectedServer}), (data) => {
			if(JSON.parse(data).error === undefined) {
            	setIpHistoryLogsData(JSON.parse(data));
            }
			else {
				console.log(JSON.parse(data).error);
            }
        });

    }, [userProfile, selectedServer.selectedServer]);

    //IpCoincidenceLogs

	const _setTypeLogs = (type) => {

		if(type !== "chatlog")
		{
			lastElement.current = null;
		}

		setTypeLogs(type);
	}

    return (
        <>
			{auth && auth.permission.indexOf("a") !== -1 &&
            <div className='profile-block'>
                {!profileNotFound ?
                (<>
                    {userProfile ?
                    (<>
                    <div className='profile-buttons'>
                            <Link onClick={(e) => {createRippleEffect(e);}} className='grey-button pos-rel' to={'/ProfilePageModule' + "?steamid=" + userProfile.profile.auth.toString()}>Профиль</Link>
                            {auth && auth.permission && auth.permission.indexOf("a") !== -1 &&
                                (<>
                                    {userProfile.profile.isadmin === 1 &&
                                    (
                                        <>
                                            {auth.permission.indexOf("b") !== -1 && 
                                                <button onClick={(e) => {createRippleEffect(e); setModalActive(true);}} className='grey-button pos-rel'>Добавить варн</button>
                                            }
                                            {auth.permission.indexOf("c") !== -1 &&
                                                <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("warnlist");}} className='grey-button pos-rel'>Список варнов</button>
                                            }
                                        </>
                                    )}
                                    {auth.permission.indexOf("d") !== -1 &&
                                        <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("chatlog");}} className='grey-button pos-rel'>Сообщения в чате</button>
                                    }
                                    {auth.permission.indexOf("i") !== -1 &&
                                        <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("ipcoincidence");}} className='grey-button pos-rel'>Совпадение по IP</button>
                                    }
                                    {auth.permission.indexOf("f") !== -1 &&
                                        <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("iphistory");}} className='grey-button pos-rel'>История IP</button>
                                    }
                                    {auth.permission.indexOf("g") !== -1 &&
                                    (
                                        <>
                                            <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("givenbans");}} className='grey-button pos-rel'>Выданные баны</button>
                                            <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("givenmutes");}} className='grey-button pos-rel'>Выданные муты</button>
                                            <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("givenwarns");}} className='grey-button pos-rel'>Выданные предупреждения</button>
                                        </>
                                    )}
                                    {auth.permission.indexOf("h") !== -1 &&
                                        <>
                                            <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("gotbans");}} className='grey-button pos-rel'>Список банов</button>
                                            <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("gotmutes");}} className='grey-button pos-rel'>Список мутов</button>
                                            <button onClick={(e) => {createRippleEffect(e); _setTypeLogs("gotwarns");}} className='grey-button pos-rel'>Список предупреждений</button>
                                        </>
                                    }
                                </>)
                            }
                    </div>
                    <div className='profile-data'>
                        <div className='profile-avatar'>
                            <img src={userProfile.profile.steaminfo.avatarfull} alt='Avatar' style={userProfile.profile.steaminfo.personastate > 0 ? ({borderColor: "#57CBDE"}) : ({borderColor: "#898989"})}/>
                            <div className='profile-text'>
                                <span className='profile-nick'>{userProfile.profile.steaminfo.personaname} <a target="_blank" rel="noreferrer" href={userProfile.profile.steaminfo.profileurl}><BsSteam size={"18px"}/></a></span>
                                <span className='profile-status' style={userProfile.profile.steaminfo.personastate > 0 ? ({color: "green"}) : ({color: "red"})}>
                                    {userProfile.profile.steaminfo.personastate > 0 ? 
                                        ("Онлайн") : ("Офлайн")
                                    }
                                </span>
                                <span className='profile-last-login'>
                                    <span>Последний заход</span>
                                    <span>{new Date(userProfile.profile.lastconnect * 1000).toLocaleString()}</span>
                                </span>
                            </div>
                        </div>
                        
                        <div className='profile-stats'>
                            <div className='profile-stats-ids'>
                                <div className='stat-column'>
                                    <span className='stat-name'>SteamID</span>
                                    <span className='stat-value'>{userProfile.profile.steamidText}</span>
                                </div>
                                <div className='stat-column'>
                                    <span className='stat-name'>SteamID64</span>
                                    <span className='stat-value'>{userProfile.profile.steaminfo.steamid}</span>
                                </div>
                                <div className='stat-column'>
                                    <span className='stat-name'>IP</span>
                                    <span className='stat-value'>{userProfile.profile.ip}</span>
                                </div>
								<div className='stat-column'>
                                    <span className='stat-name'>Сервер</span>
                                    <span className='stat-value'>{selectedServer.servers.find(x => x.server_id === userProfile.profile.server_id) !== undefined ?
										<>{selectedServer.servers.find(x => x.server_id === userProfile.profile.server_id).server_name}</>
										:
										<>Сервер не найден</>
									}</span>
                                </div>
                            </div>
                            <div className='profile-stats-time'>
                                <div className='stat-column'>
                                    <span className='stat-name'>Дней на проекте</span>
                                    <span className='stat-value'>{Math.floor(((new Date() / 1000) - userProfile.profile.first_connect) / 60 / 60 / 24)}</span>
                                </div>
                                <div className='stat-column'>
                                    <span className='stat-name'>Минут сыграно</span>
                                    <span className='stat-value'>{seconds2time(userProfile.profile.played_time)}</span>
                                </div>
                                <div className='stat-column'>
                                    <span className='stat-name'>Дата регистрации</span>
                                    <span className='stat-value'>{new Date(userProfile.profile.first_connect * 1000).toLocaleString()}</span>
                                </div>
                                <div className='stat-column'>
                                    <span className='stat-name'>Последняя активность</span>
                                    <span className='stat-value'>{new Date(userProfile.profile.lastconnect * 1000).toLocaleString()}</span>
                                </div>
                            </div>
                            <div className='profile-stats-bans'>
                                {userProfile.profile.isadmin === 1 &&
                                <div className='stat-column'>
                                    <span className='stat-name'>Получено варнов</span>
                                    <span className='stat-value'>{adminProfile && adminProfile.active_warns}</span>
                                </div>}
                                <div className='stat-column'>
                                    <span className='stat-name'>Получено банов</span>
                                    <span className='stat-value'>{adminProfile && userProfile.profile.countban ? (userProfile.profile.countban.length) : (0)}</span>
                                </div>
                                <div className='stat-column'>
                                    <span className='stat-name'>Получено мутов</span>
                                    <span className='stat-value'>{adminProfile && userProfile.profile.countmute ? (userProfile.profile.countmute.length) : (0)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                ) :
                (
                    <ContentLoader
                        viewBox="0 0 400 160"
                        height={"100%"}
                        width={"100%"}
                        backgroundColor="transparent"
                    >
                        <circle cx="150" cy="86" r="5" />
                        <circle cx="194" cy="86" r="5" />
                        <circle cx="238" cy="86" r="5" />
                    </ContentLoader>
                )}
                



                <div className='profile-logs'>
                    {typeLogs === "chatlog" && 
                    <>
                        <div className="chat-utils">
                            <input className='search-input' onChange={(e) => {SetSearchInput(e.target.value);}} value={SearchInput} placeholder='Steam ID или ник игрока'></input>
                            {calendarRefValue.current &&
                                <input id='calendar' onClick={(e) => {setCalendarModalActive(true);}} value={calendarRefValue.current[0].toLocaleDateString() + " - " + calendarRefValue.current[1].toLocaleDateString()} readOnly></input>
                            }
                            <span className='count-columns'>Кол-во записей: {customSort(Object.entries(ChatLogsData), userProfile).length}</span>
                        </div>
                        <div id='scrll-prfl' className='chat-log-block'>
                            
                            
                            {customSort(Object.entries(ChatLogsData), userProfile).slice(firstItemIndex, limit * page).map((data, index) => { return (
                                <div className='chat-message' key={index}>
                                    <div className='chat-avatar'>
                                        {sidCollection.has(data[1].auth) !== false && <>
											<Link to={"/ProfilePageModule" + "?steamid=" + sidCollection.get(data[1].auth).steamid}>
												<img src={sidCollection.get(data[1].auth).avatar} alt='Avatar'/>
											</Link>
										</>}
                                        
                                    </div>
                                    <div className='chat-content'>
                                        <div className='chat-nick'>
                                            <span>{data[1].nickname} {new Date(data[1].timestamp * 1000).toLocaleString()} {selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</span>
                                        </div>
                                        <div className='chat-text'>
                                            <p>{data[1].text}</p>
                                        </div>
                                    </div>
                                </div>
                            )})}
                            {!isChatLoading && !LoadSteamData ?
                            (<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
                            (
                                <ContentLoader
                                    viewBox="0 0 400 160"
                                    height={"100%"}
                                    width={"100%"}
                                    backgroundColor="transparent"
                                >
                                    <circle cx="150" cy="86" r="5" />
                                    <circle cx="194" cy="86" r="5" />
                                    <circle cx="238" cy="86" r="5" />
                                </ContentLoader>
                            )}
                        </div>
                    </>
                    }
                    {typeLogs === "ipcoincidence" && 
                        <div className='ip-coincidence text-align-center'>
                            <span className='count-columns'>Кол-во записей: {Object.entries(ipCoincidenceLogsData).length}</span>
                            <table>
                                <thead>
                                    <tr>
                                        {/* <th>Имя</th> */}
                                        <th>Steam ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ipCoincidenceLogsData && Object.entries(ipCoincidenceLogsData).map((data, index) => { return (
                                        <tr key={index}>
                                            {/* <td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth}>{data[1].nickname}</Link></td> */}
                                            <td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth}>{data[1].steamidText}</Link></td>
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "gotbans" && 
                        <div className='ban-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {userProfile.profile.countban ? (Object.entries(userProfile.profile.countban).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Создан</th>
                                        <th>Окончание</th>
                                        <th>Длительность</th>
                                        <th>Причина</th>
                                        <th>Сервер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProfile.profile.countban && Object.entries(userProfile.profile.countban).map((data, index) => { return (
                                        <tr key={index}>
                                            <td>{new Date(data[1].created * 1000).toLocaleString()}</td>
                                            <td>{new Date(data[1].ends * 1000).toLocaleString()}</td>
                                            <td style={{color: "#CE9178"}}>{seconds2time(data[1].length)}</td>
                                            <td>{data[1].reason}</td>
                                            {selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
												:
												<td>Сервер не найден</td>
											}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "gotmutes" && 
                        <div className='mute-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {userProfile.profile.countmute ? (Object.entries(userProfile.profile.countmute).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Создан</th>
                                        <th>Окончание</th>
                                        <th>Длительность</th>
                                        <th>Причина</th>
										<th>Сервер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProfile.profile.countmute && Object.entries(userProfile.profile.countmute).map((data, index) => { return (
                                        <tr key={index}>
                                            <td>{new Date(data[1].created * 1000).toLocaleString()}</td>
                                            <td>{new Date(data[1].ends * 1000).toLocaleString()}</td>
                                            <td style={{color: "#CE9178"}}>{seconds2time(data[1].length)}</td>
                                            <td>{data[1].reason}</td>
											{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
												:
												<td>Сервер не найден</td>
											}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
					{typeLogs === "gotwarns" && 
                        <div className='getwarn-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {userProfile.profile.count_warns ? (Object.entries(userProfile.profile.count_warns).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Имя</th>
                                        <th>Причина</th>
                                        <th>Время</th>
										<th>Сервер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProfile.profile.count_warns && Object.entries(userProfile.profile.count_warns).map((data, index) => { return (
                                        <tr key={index}>
                                            <td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth_admin}>{data[1].nick_admin}</Link></td>
                                            <td>{data[1].reason}</td>
                                            <td>{new Date(data[1].timestamp * 1000).toLocaleString()}</td>
											{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
												:
												<td>Сервер не найден</td>
											}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "warnlist" && 
                        <div className='warn-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {warnList ? (Object.entries(warnList).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Причина</th>
                                        <th>Дата выдачи</th>
                                        <th>Дата окончания</th>
										<th>Сервер</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {warnList && Object.entries(warnList).map((data, index) => { return (
                                        data[1].warn_end * 1000 < new Date().getTime() ?
                                            <>
                                                <tr key={index}>
                                                    <td><del>{data[1].reason}</del></td>
                                                    <td><del>{new Date(data[1].timestamp * 1000).toLocaleString()}</del></td>
                                                    <td style={{color: "#CE9178"}}><del>{new Date(data[1].warn_end * 1000).toLocaleString()}</del></td>
													{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
														<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
														:
														<td>Сервер не найден</td>
													}
                                                    <td><button disabled className='grey-button pos-rel'><del>Удалить</del></button></td>
                                                </tr>
                                            </> :
                                            <>
                                                <tr key={index}>
                                                    <td>{data[1].reason}</td>
                                                    <td>{new Date(data[1].timestamp * 1000).toLocaleString()}</td>
                                                    <td style={{color: "#CE9178"}}>{new Date(data[1].warn_end * 1000).toLocaleString()}</td>
													{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
														<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
														:
														<td>Сервер не найден</td>
													}
                                                    <td><button onClick={(e) => {createRippleEffect(e); decWarn(data[1].id);}} className='grey-button pos-rel'>Удалить</button></td>
                                                </tr>
                                            </>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "givenbans" && 
                        <div className='issued-ban-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {userProfile.profile.given_bans ? (Object.entries(userProfile.profile.given_bans).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Имя</th>
                                        <th>Причина</th>
                                        <th>Время</th>
                                        <th>Длительность</th>
										<th>Сервер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProfile.profile.given_bans && Object.entries(userProfile.profile.given_bans).map((data, index) => { return (
                                        <tr key={index}>
                                            <td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].authid}>{data[1].name}</Link></td>
                                            <td>{data[1].reason}</td>
                                            <td>{new Date(data[1].created * 1000).toLocaleString()}</td>
                                            <td style={{color: "#CE9178"}}>{seconds2time(data[1].length)}</td>
											{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
												:
												<td>Сервер не найден</td>
											}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "givenmutes" && 
                        <div className='issued-mute-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {userProfile.profile.given_mutes ? (Object.entries(userProfile.profile.given_mutes).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Имя</th>
                                        <th>Причина</th>
                                        <th>Время</th>
                                        <th>Длительность</th>
										<th>Сервер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProfile.profile.given_mutes && Object.entries(userProfile.profile.given_mutes).map((data, index) => { return (
                                        <tr key={index}>
                                            <td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].authid}>{data[1].name}</Link></td>
                                            <td>{data[1].reason}</td>
                                            <td>{new Date(data[1].created * 1000).toLocaleString()}</td>
											<td style={{color: "#CE9178"}}>{seconds2time(data[1].length)}</td>
											{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
												:
												<td>Сервер не найден</td>
											}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "givenwarns" && 
                        <div className='issued-warnings-logs text-align-center'>
                            <span className='count-columns'>Кол-во записей: {userProfile.profile.warns_given ? (Object.entries(userProfile.profile.warns_given).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Имя</th>
                                        <th>Причина</th>
                                        <th>Время</th>
                                        {/* <th>Длительность</th> */}
										<th>Сервер</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProfile.profile.warns_given && Object.entries(userProfile.profile.warns_given).map((data, index) => { return (
                                        <tr key={index}>
                                            <td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth_target}>{data[1].nick_target}</Link></td>
                                            <td>{data[1].reason}</td>
                                            <td>{new Date(data[1].timestamp * 1000).toLocaleString()}</td>
											{/* <td>{seconds2time(data[1].length)}</td> */}
											{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
												:
												<td>Сервер не найден</td>
											}
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                    {typeLogs === "iphistory" && 
                        <div className='ip-history text-align-center'>
                            <span className='count-columns'>Кол-во записей: {ipHistoryLogsData ? (Object.entries(ipHistoryLogsData).length) : (0)}</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>IP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ipHistoryLogsData && Object.entries(ipHistoryLogsData).map((data, index) => { return (
                                        <tr key={index}>
                                            <td>{data[1].ip}</td>
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                </>) : (
                    <div className='wd-100 text-align-center'><span className='fs-22'>Профиль не найден ни на одном сервере</span></div>
                )}
            </div>}



            {modalActive && (
                <div className='profile-modal-warn' onClick={(e) => {setModalActive(false); setReasonInput(""); setWarnLengthInput(""); }}>
                    <div className='warn-details' onClick={(e) => {e.stopPropagation();}}>
                        <input className='search-input' onChange={(e) => {setReasonInput(e.target.value);}} value={reasonInput} placeholder='Причина'/>
                        <input className='search-input' onChange={(e) => {if(new RegExp(/^\d+$/).test(e.target.value) || e.target.value === "") setWarnLengthInput(e.target.value); }} value={warnLengthInput} placeholder='Длиительность'/>
                        <button onClick={(e) => { createRippleEffect(e); setModalActive(false); incWarn(); setReasonInput(""); setWarnLengthInput(""); }} className='grey-button pos-rel'>Отправить</button>
                    </div>
                </div>
            )}
            {/* {calendarModalActive && (
                <div className='chat-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <Calendar onChange={(dates) => {calendarRefValue.current = dates;}} defaultValue={[new Date(), new Date()]} returnValue={"range"} selectRange={true} />
                    </div>
                </div>
            )} */}
			{calendarModalActive && (
                <div className='chat-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <CalendarModule setDateInfo={(dates) => calendarRefValue.current = dates} date={
							[
								{
								  startDate: calendarRefValue.current[0],
								  endDate: calendarRefValue.current[1],
								  key: 'selection'
								}
							]
						} />
                    </div>
                </div>
            )}
        </>
    );
};
