const defaultState = {
	selectedServer: 0,
	servers: [
		{
			"server_id": 0,
			"server_name": "Все сервера"
		}
	]
};

const SERVERS = "SERVERS";
const SELECTED = "SELECTED";
const SERVERS_REFRESH = "SERVERS_REFRESH";
const SERVERS_ADD = "SERVERS_ADD";

export const servers = (state = defaultState, action) => {
	switch(action.type) {
		case SERVERS: {
			return {...state, servers: [...state.servers, ...action.payload.servers]}
		}
		case SELECTED: {
			return {...state, selectedServer: action.payload}
		}
		case SERVERS_REFRESH: {
			return {...action.payload}
		}
		case SERVERS_ADD: {
			return {...state, servers: action.payload}
		}
		default:
			return state;
	}
}

// export const asyncAuthUser = (name, pass) => 
// 	function (dispatch) {
// 		let query = "api/server-side/api/source/GetAuthAdmin.php?name=" + name + "&pass=\"" + pass + "\"";
// 		fetch(query)
// 			.then(response => response.json())
// 			.then(result => {
// 				if(result[0].hasOwnProperty("error") === false && result[0].hasOwnProperty("auth") === true)
// 					dispatch(authUser(result[0].auth));
// 			});
// 	}

export const serversData = (payload) => ({type: SERVERS, payload})
export const selectedServer = (payload) => ({type: SELECTED, payload})
export const serversRefresh = (payload) => ({type: SERVERS_REFRESH, payload})
export const serversAdd = (payload) => ({type: SERVERS_ADD, payload})