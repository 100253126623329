import React, { useEffect, useState } from 'react';
import './CustomSelect.scss';
import { useDispatch, useSelector } from 'react-redux';
import { socketIO } from '..';
import { selectedServer, serversData } from '../store/servers';
//import Select from 'react-select'

export const CustomSelect = () => {

    const serversList = useSelector(state => state.servers);
    const dispatch = useDispatch();
    //const [options, setOptions] = useState([]);
    let options;

    useEffect(() => {

        socketIO.emit("GetServerList", (data) => {
            if(data.success !== 404) {
                dispatch(serversData(JSON.parse(data)));
				// if(JSON.parse(data).servers !== undefined && JSON.parse(data).servers[0] !== undefined)
				// {
				// 	dispatch(selectedServer(JSON.parse(data).servers[0].server_id));
				// }
				// console.log(JSON.parse(data));
            } else {
                console.log("GetServerList 404");
            }
        });

    }, []);

	// useEffect(() => {
        
	// 	if(serversList.servers !== undefined && serversList.servers[0] !== undefined)
	// 	{
	// 		dispatch(selectedServer(serversList.servers[0].server_id));
	// 	}
    // }, [serversList]);

    /*useEffect(() => {
        let buff = [];

        if(serversList.servers) {
            Object.entries(serversList.servers).map((data, index) => {
                buff.push({value: data[1].server_id, label: data[1].server_name});
            });

            setOptions(buff);

            console.log(buff[0]);
    
            
        }
    }, [serversList.servers]);*/

    if(serversList.servers) {
        options = Object.entries(serversList.servers).map((data, index) => {
            return (<option value={data[1].server_id} key={index}>{data[1].server_name}</option>);
        });
    }
    
    return (
        <>
            {/* <Select defaultValue={options[0]} options={options} /> */}

            <select className='custom-select' onChange={(event) => {dispatch(selectedServer(event.target.value));}}>
                {options}
            </select>
        </>
    );
};
