import React, { useEffect, useState } from 'react';
import './LogsModule.scss';
import { socketIO } from '../..';
import { useSearchParams } from 'react-router-dom';

export const TextLogsPageModule = () => {

    const [FileText, SetFileText] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const fileid = searchParams.get("id");

    useEffect(() => {

        socketIO.on("GetLDATAClientFromServer", (data) => {
            if(JSON.parse(data).error === undefined) {
                SetFileText(JSON.parse(data));
            } else {
                console.log(JSON.parse(data).error);
            }
        });

        socketIO.on("GettErrorDataClientFromServer", (data) => {
            if(JSON.parse(data).error === undefined) {
                SetFileText(JSON.parse(data));
            } else {
                console.log(JSON.parse(data).error);
            }
        });

        if(fileid === 1) {
            socketIO.emit("StartGetLDATAServer");
        } else if(fileid === 2) {
            socketIO.emit("StartGetErrorDataServer");
        }

        return () => {
            socketIO.off("GetLDATAClientFromServer");
            socketIO.off("GettErrorDataClientFromServer");
        }
        
    }, [socketIO]);

    return (
        <>
            <div className='text-log-block'>
                <span className='text'>
                    {FileText}
                </span>
            </div>
        </>
    );
};
