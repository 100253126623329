import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import { tempData } from './tempData';
import { auth } from './auth';
import { servers } from './servers';

const rootReducer = combineReducers({
		mainData: tempData,
		auth: auth,
		servers: servers,
	}
);

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));