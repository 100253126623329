import React, { useEffect, useRef, useState } from 'react';
import './AdminsModule.scss';
import { socketIO } from '../..';
import {createRippleEffect} from '../../utils/RippleEffect.js';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const AdminsPageModule = () => {

	const selectedServer = useSelector(state => state.servers);

	const [SearchInput, SetSearchInput] = useState("");
	const [SearchPermissionInput, SetSearchPermissionInput] = useState("");
	const [adminsList, setAdminsList] = useState(null);

    const [isDataLoading, setIsDataLoading] = useState(true);

    const lastElement = useRef();

	const [modalActive, setModalActive] = useState(false);
    const [adminModalAuth, setAdminModalAuth] = useState("");
    const [adminModalPermission, setAdminModalPermission] = useState("");
	
    const [deleteAdminModalActive, setDeleteAdminModalActive] = useState(false);
    const [deleteAdminSteamID, setDeleteAdminSteamID] = useState(null);
    const [deleteServerID, setDeleteServerID] = useState(null);

	const auth = useSelector(state => state.auth);

	const SteamID = require('steamid');

    var regex = new RegExp(/^(1|[1-9][0-9]{0,16})$/);

	useEffect(() => {
		socketIO.emit("GetAdminList", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
            if(JSON.parse(data).success === 200) {
                setAdminsList(JSON.parse(data).admins);
				setIsDataLoading(false);
				// console.log(JSON.parse(data));
            }
        });
	}, [selectedServer.selectedServer]);

	// useEffect(() => {
	// 	console.log(adminsList);
	// }, [adminsList]);

    function addAdmin(serverid) {
		const sid64 = new SteamID(adminModalAuth).getSteamID64();
        socketIO.emit("InsertNewAdmin", JSON.stringify({"auth": sid64, "permission": adminModalPermission, "server_id": serverid}), (data) => {
            if(JSON.parse(data).success === 200) {
                setAdminsList(JSON.parse(data).admins);
				// console.log(JSON.parse(data).admins);
            } else {
				console.log(JSON.parse(data).error);
			}
        });
    }

    function deleteAdmin() {
        if(!deleteAdminSteamID || deleteServerID === null) return;

		// console.log(deleteServerID);

        socketIO.emit("RemoveAdmin", JSON.stringify({"steamid": deleteAdminSteamID, "server_id": deleteServerID, "update_sid": selectedServer.selectedServer}), (data) => {
            if(JSON.parse(data).success === 200) {
				// console.log(JSON.parse(data).admins);
                setAdminsList(JSON.parse(data).admins);
            } else {
				console.log(JSON.parse(data).error);
			}
        });
    }

	const customSort = (arr) => {
        if(arr === null) return [];

        arr = arr.filter(element => (element[1].profile !== undefined && element[1].profile.personaname.toLowerCase().includes(SearchInput.toLowerCase())) && 
                                    (element[1].permission !== undefined && element[1].permission.toLowerCase().includes(SearchPermissionInput.toLowerCase())));

        return [].concat(arr);
    }



    return (
        <>
		{auth && auth.permission.indexOf("m") !== -1 &&
            <div className='admin-settings-block text-align-center'>
				<span className='text-align-center'>Настройка админов</span>
				<div className='admin-utils'>
					<input className='search-input' onChange={(e) => {SetSearchInput(e.target.value);}} value={SearchInput} placeholder='Поиск админа'></input>
					<input className='search-input' onChange={(e) => {SetSearchPermissionInput(e.target.value);}} value={SearchPermissionInput} placeholder='Поиск по правам'></input>
					{selectedServer.selectedServer > 0 && <button onClick={(e) => {createRippleEffect(e); setModalActive(true);}} className='grey-button pos-rel'>Добавить админа</button>}
				</div>
				<div className='admin-list'>
					<table>
						<thead>
							<tr>
								<th>Имя</th>
								<th>Steam ID</th>
								{/* <th>Варны</th> */}
								<th>Права</th>
								<th>Сервер</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{adminsList !== null && (customSort(Object.entries(adminsList)).map((data, index) => { return (
								<tr key={index}>
									{data[1].profile !== undefined &&
									<>
										<td id='nickname'><Link to={"/ProfilePageModule" + "?steamid=" + data[1].profile.steamid}>{data[1].profile.personaname}</Link></td>
										<td id='steamid'>{data[1].steamidText}</td>
										{/* <td id='warns'>{data[1].warns}</td> */}
										<td id='permission'>{data[1].permission}</td>
										<td id='servername'>{
											selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
											selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name : "Не обнаружен"
										}</td>
										<td><button onClick={(e) => {createRippleEffect(e); setDeleteAdminSteamID(data[1].auth); setDeleteServerID(data[1].server_id); setDeleteAdminModalActive(true);}} className='grey-button pos-rel'>Удалить</button></td>
									</>}
								</tr>
							)}))}
						</tbody>
					</table>
					{/* {adminsList && customSort(Object.entries(adminsList)).map((data, index) => { return (
						<div className='admin-data' key={index}>
							<span id='nickname'>{data[1].profile.personaname}</span>
							<span id='teamid'>{data[1].steamidText}</span>
							<span id='warns'>{data[1].warns}</span>
							<span id='permission'>{data[1].permission}</span>

							<button onClick={(e) => {createRippleEffect(e); setDeleteAdminSteamID(data[1].auth); setDeleteAdminModalActive(true);}} className='grey-button pos-rel'>Удалить</button>
						</div>
					)})} */}
					{!isDataLoading ?
					(<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
					(
						<ContentLoader
							viewBox="0 0 400 160"
							height={"100%"}
							width={"100%"}
							backgroundColor="transparent"
						>
							<circle cx="150" cy="86" r="5" />
							<circle cx="194" cy="86" r="5" />
							<circle cx="238" cy="86" r="5" />
						</ContentLoader>
					)}
				</div>
            </div>}

			{modalActive && (
                <div className='admin-add-modal' onClick={(e) => {setModalActive(false); setAdminModalAuth(""); setAdminModalPermission("");}}>
                    <div className='admin-details' onClick={(e) => {e.stopPropagation();}}>
						<input id='auth' className='admin-data-input' onChange={(e) => { setAdminModalAuth(e.target.value); } } value={adminModalAuth} placeholder='Steam ID'></input>
						<input id='permission' className='admin-data-input' onChange={(e) => {setAdminModalPermission(e.target.value);}} value={adminModalPermission} placeholder='Права'></input>

                        <button onClick={(e) => {createRippleEffect(e); setModalActive(false); if(new SteamID(adminModalAuth).isValid()) {addAdmin(selectedServer.selectedServer);} setAdminModalAuth(""); setAdminModalPermission("");}} className='grey-button pos-rel'>Отправить</button>
                    </div>
                </div>
            )}
            {deleteAdminModalActive && (
                <div className='admin-delete-modal' onClick={(e) => {setDeleteAdminSteamID(null); setDeleteServerID(null); setDeleteAdminModalActive(false);}}>
                    <div className='admin-details' onClick={(e) => {e.stopPropagation();}}>
                        <span>Вы точно хотите удалить этого админа?</span>

                        <div className='time-to-choose'>
                            <button id='yes' onClick={(e) => {createRippleEffect(e); deleteAdmin(); setDeleteAdminModalActive(false);}} className='grey-button pos-rel'>Да</button>
                            <button id='no' onClick={(e) => {createRippleEffect(e); setDeleteAdminSteamID(null); setDeleteServerID(null); setDeleteAdminModalActive(false);}} className='grey-button pos-rel'>Нет</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
