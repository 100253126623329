import React from 'react';
import "./NotFoundModule.scss";
import NotFound from '../../images/404.svg';
import { Link } from 'react-router-dom';

export const NotFoundModule = () => {
	return (
		<div className='not-found align-items-center'>
			<span>Страница не найдена</span>
			<Link to={"/"}>На главную</Link>
			<img src={NotFound} alt='Error 404'/>
		</div>
	);
};
