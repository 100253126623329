const defaultState = null;

const AUTH_USER = "AUTH_USER";

export const auth = (state = defaultState, action) => {
	switch(action.type) {
		case AUTH_USER: {
			// запрос к серверу авторизации
			return {...state, ...action.payload}
		}
		default:
			return state;
	}
}

// export const asyncAuthUser = (name, pass) => 
// 	function (dispatch) {
// 		let query = "api/server-side/api/source/GetAuthAdmin.php?name=" + name + "&pass=\"" + pass + "\"";
// 		fetch(query)
// 			.then(response => response.json())
// 			.then(result => {
// 				if(result[0].hasOwnProperty("error") === false && result[0].hasOwnProperty("auth") === true)
// 					dispatch(authUser(result[0].auth));
// 			});
// 	}

export const authUser = (payload) => ({type: AUTH_USER, payload})