import React, { useState, useEffect, useRef } from 'react';
import './LogsModule.scss';
import { socketIO } from '../..';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HashMap from 'hashmap';

export const AllPlayersLogsPageModule = () => {

    const [SearchInput, SetSearchInput] = useState("");
    const [AllUsersLogsData, SetAllUsersLogsData] = useState({});
	const [sidCollection, SetSidCollection] = useState(new HashMap());

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    let firstItemIndex = 0;
    const [isChatLoading, setIsChatLoading] = useState(true);
	const [LoadSteamData, setLoadSteamData] = useState(false);

    const lastElement = useRef();
    const firstLoad = useRef();
    const lastid = useRef();
    const observer = useRef();

    const selectedServer = useSelector(state => state.servers);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        firstLoad.current = true;

        // socketIO.emit("GetUserList", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
		// 	if(JSON.parse(data).success === 200) {
        //     	SetAllUsersLogsData(JSON.parse(data).users);
		// 		console.log(JSON.parse(data).users);
		// 	} else {
		// 		console.log("error get user list");
		// 	}
        // });
    }, []);

    useEffect(() => {
		lastid.current = 0;
		SetAllUsersLogsData({});
		setPage(1);
        // socketIO.emit("GetUserList", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
		// 	let res = JSON.parse(data);
        //     if(res.success === 200)
		// 	{
		// 		// Формирование массива sid_collection который содержит steamid, для которых необходимо получить информацию
		// 		setLoadSteamData(true);

		// 		const filtered_data = customSort(Object.entries(res.users)).slice(0, limit);
		// 		let sid_collection = [];

		// 		filtered_data.forEach((filtered_data_value, index) => {
		// 			if(sidCollection.has(filtered_data_value[1].auth) === false)
		// 				sid_collection.push(filtered_data_value[1].auth);
		// 		});

		// 		if(sid_collection.length !== 0)
		// 		{
		// 			socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
		// 				sid_info = JSON.parse(sid_info);
		// 				if(sid_info.success !== 200)
		// 				{
		// 					console.log("Error get SteamID's info from VisitLogsModule");
		// 					return;
		// 				}

		// 				const hash_sids = new HashMap(sid_info.steam_data);

		// 				let sidCollectionClone = sidCollection.clone();

		// 				hash_sids.forEach((kvalue, key) => {
		// 					sidCollectionClone.set(key, kvalue);
		// 				});

		// 				SetSidCollection(sidCollectionClone);
		// 				SetAllUsersLogsData(res.users);

		// 				setLoadSteamData(false);
		// 			});
		// 		} else {
		// 			SetAllUsersLogsData(res.users);
		// 			setLoadSteamData(false);
		// 		}
		// 	} else {
		// 		console.log("error get user list");

		// 		SetAllUsersLogsData({});
		// 		setLoadSteamData(false);
		// 	}
        // });
    }, [selectedServer.selectedServer]);

	useEffect(() => {
		const timeOutHandler = setTimeout(() => {
			// setLoadSteamData(true);

			// const filtered_data = customSort(Object.entries(AllUsersLogsData)).slice(limit * (page - 1), limit * page);
			// let sid_collection = [];

			// filtered_data.forEach((filtered_data_value, index) => {
			// 	if(sidCollection.has(filtered_data_value[1].auth) === false)
			// 		sid_collection.push(filtered_data_value[1].auth);
			// });

			// if(sid_collection.length !== 0)
			// {
			// 	socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
			// 		sid_info = JSON.parse(sid_info);
			// 		if(sid_info.success !== 200)
			// 		{
			// 			console.log("Error get SteamID's info from AllPlayersLogsPageModule");
			// 			return;
			// 		}

			// 		const hash_sids = new HashMap(sid_info.steam_data);

			// 		let sidCollectionClone = sidCollection.clone();

			// 		hash_sids.forEach((kvalue, key) => {
			// 			sidCollectionClone.set(key, kvalue);
			// 		});

			// 		SetSidCollection(sidCollectionClone);

			// 		setLoadSteamData(false);
			// 	});
			// } else {
			// 	setLoadSteamData(false);
			// }

			socketIO.emit("GetUserList", JSON.stringify({
				"server_id": selectedServer.selectedServer,
				"get": limit,
				"lastid": lastid.current,
				"search": SearchInput
			}), (data) => {
				let res = JSON.parse(data);
				if(res.success === 200)
				{
					// Формирование массива sid_collection который содержит steamid, для которых необходимо получить информацию
					setLoadSteamData(true);

					// console.log(res.users)
					// console.log(Object.keys(res.users))
					// console.log(Object.keys(res.users).length)
					// console.log(res[Object.keys(res.users).length])
					// console.log(res[Object.keys(res.users).length - 1])

					if(res.users[Object.keys(res.users).length - 1] !== undefined)
					{
						lastid.current = res.users[Object.keys(res.users).length - 1].id;
						// console.log(lastid.current)
					}
	
					const filtered_data = Object.entries(res.users);
					let sid_collection = [];
	
					filtered_data.forEach((filtered_data_value, index) => {
						if(sidCollection.has(filtered_data_value[1].auth) === false)
							sid_collection.push(filtered_data_value[1].auth);
					});
	
					if(sid_collection.length !== 0)
					{
						socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
							sid_info = JSON.parse(sid_info);
							if(sid_info.success !== 200)
							{
								console.log("Error get SteamID's info from VisitLogsModule");
								return;
							}
	
							const hash_sids = new HashMap(sid_info.steam_data);
	
							let sidCollectionClone = sidCollection.clone();
	
							hash_sids.forEach((kvalue, key) => {
								sidCollectionClone.set(key, kvalue);
							});
	
							SetSidCollection(sidCollectionClone);
							SetAllUsersLogsData(prevAllPlayersLogsData => {
								return Object.assign({}, Object.values(prevAllPlayersLogsData).concat(Object.values(res.users)));
							});
	
							setLoadSteamData(false);
						});
					} else {
						SetAllUsersLogsData(prevAllPlayersLogsData => {
							return Object.assign({}, Object.values(prevAllPlayersLogsData).concat(Object.values(res.users)));
						});
						setLoadSteamData(false);
					}
				} else {
					console.log("error get user list: " + res.error.toString());
	
					// SetAllUsersLogsData({});
					setLoadSteamData(false);
				}
			});
		}, 500);

		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, SearchInput, selectedServer.selectedServer]);

    useEffect(() => {
        if(isChatLoading || lastElement.current === null  || LoadSteamData) return;
        
        if(observer.current) {
            observer.current.disconnect();
        }
        
        var callback = function(entries, observer) {
            if(entries[0].isIntersecting && (limit * page) < (Object.entries(AllUsersLogsData).length + limit)) {
                setPage(page + 1);
            }
            firstLoad.current = false;
        };
        
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current);
    }, [isChatLoading, AllUsersLogsData, LoadSteamData]);

	useEffect(() => {
        setIsChatLoading(true);

		const timeOutHandler = setTimeout(() =>	setIsChatLoading(false), 1000);
  
		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page]);
    
    useEffect(() => {
        const timeOutHandler = setTimeout(() => {
			lastid.current = 0;
			SetAllUsersLogsData({});

			if(page !== 1)
			{
				setPage(1);
			}
		}, 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};

    }, [SearchInput]);

    const customSort = (arr) => {
        if(arr === null) return [];


        arr = arr.filter(element => (element[1].nickname !== undefined && element[1].nickname.toLowerCase().includes(SearchInput.toLowerCase())) || 
                                    (element[1].steamidText !== undefined && element[1].steamidText.toLowerCase().includes(SearchInput.toLowerCase())) ||
                                    (element[1].first_connect !== undefined && new Date(element[1].first_connect * 1000).toLocaleString().toLowerCase().includes(SearchInput.toLowerCase())));
        return [].concat(arr);
        //return [].concat(arr);
    }

    return (
        <>
        {auth && auth.permission.indexOf("l") !== -1 && 
            <div className='users-log-block'>
                <div className="users-utils">
                    <input className='search-input' onChange={(e) => {SetSearchInput(e.target.value);}} value={SearchInput} placeholder='Steam ID или ник игрока'></input>
                    <span className='count-columns'>Кол-во записей: {AllUsersLogsData ? (Object.entries(AllUsersLogsData).length) : (0)}</span>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Имя</th>
                            <th>Steam ID</th>
                            <th>Первый вход</th>
                        </tr>
                    </thead>
                    <tbody className='text-align-center'>
                        {AllUsersLogsData && Object.entries(AllUsersLogsData).map((data, index) => { return (
                            <tr key={index}>
                                {sidCollection.has(data[1].auth) !== false && <>
									<td id='avatar'><Link to={"/ProfilePageModule" + "?steamid=" + sidCollection.get(data[1].auth).steamid}><img src={sidCollection.get(data[1].auth).avatarfull} alt='Avatar'/></Link></td>
									<td id='nickname'><Link to={"/ProfilePageModule" + "?steamid=" + sidCollection.get(data[1].auth).steamid}>{sidCollection.get(data[1].auth).personaname}</Link></td>
									<td id='steamid'>{data[1].steamidText}</td>
									<td id='first_login'>{new Date(data[1].first_connect * 1000).toLocaleString()}</td>
								</>}
                            </tr>
                        )})}
                    </tbody>
                </table>
                {!isChatLoading && !LoadSteamData ?
                (<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
                (
                    <ContentLoader
                        viewBox="0 0 400 160"
                        height={"100%"}
                        width={"100%"}
                        backgroundColor="transparent"
                    >
                        <circle cx="150" cy="86" r="5" />
                        <circle cx="194" cy="86" r="5" />
                        <circle cx="238" cy="86" r="5" />
                    </ContentLoader>
                )}
            </div>}
        </>
    );
};
