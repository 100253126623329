import React from 'react';
import { useDispatch } from 'react-redux';

import { BiSupport } from 'react-icons/bi'

import { setCurrentModule } from '../../store/tempData';

export const SupportCardModule = () => {

	const dispatch = useDispatch();

	return (
		<div onClick={ () => dispatch(setCurrentModule("SupportPageModule")) } className='flex-card-image text-align-center align-items-center'>
			<BiSupport size={"28px"}/>
			<span>Поддержка</span>
		</div>
	);
};
