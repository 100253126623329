export const createRippleEffect = (e) => {
    let x = e.nativeEvent.clientX - e.currentTarget.getBoundingClientRect().left;
    let y = e.nativeEvent.clientY - e.currentTarget.getBoundingClientRect().top;

    let ripples = document.createElement("span");
    ripples.style.left = x + "px";
    ripples.style.top = y + "px";
    ripples.className = "rippleEffect";

    e.target.appendChild(ripples);

    setTimeout(() => ripples.remove(), 500);
}