import React from 'react';
import { useDispatch } from 'react-redux';

import { RiShieldUserFill } from 'react-icons/ri'

import { setCurrentModule } from '../../store/tempData';

export const AdminsCardModule = () => {

	const dispatch = useDispatch();

	return (
		<div onClick={ () => dispatch(setCurrentModule("AdminsPageModule")) } className='flex-card-image text-align-center align-items-center'>
			<RiShieldUserFill size={"28px"}/>
			<span>Админы</span>
		</div>
	);
};
