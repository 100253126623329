import React from 'react';
import { CardModule } from '../..';
import { Link } from 'react-router-dom';
import "./NavigationPanel.scss";
import logo from '../../images/logo.svg';
import { useSelector } from 'react-redux';

export const NavigationPanel = () => {

	const auth = useSelector(state => state.auth);

	const cards = [];

	CardModule.forEach((value, key) => {
		cards.push(<Link className="nav-card" to={"/" + key} key={key}>{value}</Link>);
	})

  	return(
		<div className='header align-items-center'>
			<img src={logo} alt='Logo' className='logo'/>
			{auth && cards}
		</div>
	);
};