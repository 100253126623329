import React, { useEffect, useRef, useState } from 'react';
import './LogsModule.scss';
import { socketIO } from '../..';
import ContentLoader from 'react-content-loader';
import { CalendarModule } from '../../utils/CalendarModule';
import { Link } from 'react-router-dom';
import HashMap from 'hashmap';

import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';

export const VisitLogsModule = () => {

    const [SearchInput, SetSearchInput] = useState("");
    const [ConnectionLogs, SetConnectionLogs] = useState({});
	const [sidCollection, SetSidCollection] = useState(new HashMap());

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    let firstItemIndex = 0;
    const [isChatLoading, setIsChatLoading] = useState(true);
	const [LoadSteamData, setLoadSteamData] = useState(false);

    const lastElement = useRef();
    const firstLoad = useRef();
	const lastid = useRef();
    const observer = useRef();

    const [calendarModalActive, setCalendarModalActive] = useState(false);
    const calendarRefValue = useRef();

    const selectedServer = useSelector(state => state.servers);

    const [tableColumnSort, setTableColumnSort] = useState("");
    const [updownSort, setUpdownSort] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        firstLoad.current = true;

        calendarRefValue.current = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)];

        // socketIO.emit("GetConnectionLogs", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
		// 	if(JSON.parse(data).error === undefined) {
        //         SetConnectionLogs(JSON.parse(data));
        //     } else {
        //         SetConnectionLogs({});
        //     }
        // });
    }, []);

    useEffect(() => {

		lastid.current = 0;
		SetConnectionLogs({});
		setPage(1);
        // socketIO.emit("GetConnectionLogs", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
		// 	let res = JSON.parse(data);
		// 	if(!res.hasOwnProperty("error"))
		// 	{
		// 		// Формирование массива sid_collection который содержит steamid, для которых необходимо получить информацию
		// 		setLoadSteamData(true);

		// 		const filtered_data = customSort(Object.entries(res)).slice(0, limit);
		// 		let sid_collection = [];

		// 		filtered_data.forEach((filtered_data_value, index) => {
		// 			if(sidCollection.has(filtered_data_value[1].auth) === false)
		// 				sid_collection.push(filtered_data_value[1].auth);
		// 		});

		// 		if(sid_collection.length !== 0)
		// 		{
		// 			socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
		// 				sid_info = JSON.parse(sid_info);
		// 				if(sid_info.success !== 200)
		// 				{
		// 					console.log("Error get SteamID's info from VisitLogsModule");
		// 					return;
		// 				}

		// 				const hash_sids = new HashMap(sid_info.steam_data);

		// 				let sidCollectionClone = sidCollection.clone();

		// 				hash_sids.forEach((kvalue, key) => {
		// 					sidCollectionClone.set(key, kvalue);
		// 				});

		// 				SetSidCollection(sidCollectionClone);
		// 				SetConnectionLogs(res);

		// 				setLoadSteamData(false);
		// 			});
		// 		} else {
		// 			SetConnectionLogs(res);
		// 			setLoadSteamData(false);
		// 		}
        //     } else {
        //         SetConnectionLogs({});
		// 		setLoadSteamData(false);
        //     }
        // });
    }, [selectedServer.selectedServer, calendarRefValue.current]);

	useEffect(() => {
		const timeOutHandler = setTimeout(() => {
			// setLoadSteamData(true);

			// const filtered_data = customSort(Object.entries(ConnectionLogs)).slice(limit * (page - 1), limit * page);
			// let sid_collection = [];

			// filtered_data.forEach((filtered_data_value, index) => {
			// 	if(sidCollection.has(filtered_data_value[1].auth) === false)
			// 		sid_collection.push(filtered_data_value[1].auth);
			// });

			// if(sid_collection.length !== 0)
			// {
			// 	socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
			// 		sid_info = JSON.parse(sid_info);
			// 		if(sid_info.success !== 200)
			// 		{
			// 			console.log("Error get SteamID's info from VisitLogsModule");
			// 			return;
			// 		}

			// 		const hash_sids = new HashMap(sid_info.steam_data);

			// 		let sidCollectionClone = sidCollection.clone();

			// 		hash_sids.forEach((kvalue, key) => {
			// 			sidCollectionClone.set(key, kvalue);
			// 		});

			// 		SetSidCollection(sidCollectionClone);

			// 		setLoadSteamData(false);
			// 	});
			// } else {
			// 	setLoadSteamData(false);
			// }

			socketIO.emit("GetConnectionLogs", JSON.stringify({
				"server_id": selectedServer.selectedServer,
				"get": limit,
				"lastid": lastid.current,
				"search": SearchInput,
				"date_start": Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000),
				"date_end": Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)
			}), (data) => {
				let res = JSON.parse(data);
				if(!res.hasOwnProperty("error"))
				{
					// Формирование массива sid_collection который содержит steamid, для которых необходимо получить информацию
					setLoadSteamData(true);

					if(res[Object.keys(res).length - 1] !== undefined)
					{
						lastid.current = res[Object.keys(res).length - 1].id;
					}
	
					const filtered_data = Object.entries(res);
					let sid_collection = [];
	
					filtered_data.forEach((filtered_data_value, index) => {
						if(sidCollection.has(filtered_data_value[1].auth) === false)
							sid_collection.push(filtered_data_value[1].auth);
					});
	
					if(sid_collection.length !== 0)
					{
						socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
							sid_info = JSON.parse(sid_info);
							if(sid_info.success !== 200)
							{
								console.log("Error get SteamID's info from VisitLogsModule");
								return;
							}
	
							const hash_sids = new HashMap(sid_info.steam_data);
	
							let sidCollectionClone = sidCollection.clone();
	
							hash_sids.forEach((kvalue, key) => {
								sidCollectionClone.set(key, kvalue);
							});
	
							SetSidCollection(sidCollectionClone);
							SetConnectionLogs(prevConnectionLogsData => {
								return Object.assign({}, Object.values(prevConnectionLogsData).concat(Object.values(res)));
							});
	
							setLoadSteamData(false);
						});
					} else {
						SetConnectionLogs(prevConnectionLogsData => {
							return Object.assign({}, Object.values(prevConnectionLogsData).concat(Object.values(res)));
						});
						setLoadSteamData(false);
					}
				} else {
					// SetConnectionLogs({});
					setLoadSteamData(false);
				}
			});
		}, 250);

		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, SearchInput, selectedServer.selectedServer, calendarRefValue.current]);

    useEffect(() => {
        if(isChatLoading || lastElement.current === null || LoadSteamData) return;
        
        if(observer.current) {
            observer.current.disconnect();
        }
        
        var callback = function(entries, observer) {
            if(entries[0].isIntersecting && (limit * page) < (customSort(Object.entries(ConnectionLogs)).length + limit)) {
                setPage(page + 1);
            }
            firstLoad.current = false;
        };
        
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current);
    }, [isChatLoading, ConnectionLogs, LoadSteamData]);

	useEffect(() => {
        setIsChatLoading(true);

		const timeOutHandler = setTimeout(() =>	setIsChatLoading(false), 1000);
        
		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, calendarRefValue.current]);
    
    useEffect(() => {
        const timeOutHandler = setTimeout(() => {
			lastid.current = 0;
			SetConnectionLogs({});

			if(page !== 1)
			{
				setPage(1);
			}
		}, 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};

    }, [SearchInput]);

    function updownSotrChange() {
        if(updownSort) {
            setUpdownSort(false);
        } else {
            setUpdownSort(true);
        }
    }

    const customSort = (arr) => {
        if(arr === null) return [];

		// (element[1].nickname !== undefined && element[1].nickname.toLowerCase().includes(SearchInput.toLowerCase()))

        // arr = arr.filter(element => (Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000) <= element[1].timestamp && 
        //                             element[1].timestamp <= Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)));
        
        if(tableColumnSort == "time-login" && updownSort) {
            return [].concat(arr).sort((a, b) => parseFloat(a[1].timestamp) - parseFloat(b[1].timestamp));
        } else if(tableColumnSort == "time-login" && !updownSort) {
            return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        }
        return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        //return [].concat(arr);
    }

    return (
        <>
        {auth && auth.permission.indexOf("i") !== -1 &&
            <div className='visit-log-block text-align-center'>
                {calendarRefValue.current &&
                        <input id='calendar' onClick={(e) => {setCalendarModalActive(true);}} value={calendarRefValue.current[0].toLocaleDateString() + " - " + calendarRefValue.current[1].toLocaleDateString()} readOnly></input>
                    }
                <div className='visit-utils'>
                    <input className='search-input' onChange={(e) => {SetSearchInput(e.target.value)}} value={SearchInput} placeholder='Steam ID или ник игрока'></input>
                    
                    <span className='count-columns'>Кол-во записей: {customSort(Object.entries(ConnectionLogs)).length}</span>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Имя</th>
                            <th>Steam ID</th>
                            <th id='time-login' onClick={(e) => {setTableColumnSort(e.currentTarget.id); updownSotrChange();}} style={{cursor: "pointer"}}>Время</th>
                            <th>Вход/Выход</th>
                            <th>Сервер</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customSort(Object.entries(ConnectionLogs)).map((data, index) => { return (
                            <tr key={index}>
								{sidCollection.has(data[1].auth) !== false &&
								<>
									<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth.toString()}><img src={sidCollection.get(data[1].auth).avatarfull} alt='Avatar'/></Link></td>
									<td><Link to={"/ProfilePageModule" + "?steamid=" + data[1].auth.toString()}>{data[1].nickname}</Link></td>
									<td>{data[1].steamidText}</td>
									<td>{new Date(data[1].timestamp * 1000).toLocaleString()}</td>
									<td style={data[1].is_connect ? ({color: "green"}) : ({color: "red"})}>{data[1].is_connect ? ("Вход") : ("Выход")}</td>
									{selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
										<td>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</td>
										:
										<td>Сервер не найден</td>
									}
								</>}
							</tr>
                        )})}
                    </tbody>
                </table>
                {!isChatLoading && !LoadSteamData ?
                (<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
                (
                    <ContentLoader
                        viewBox="0 0 400 160"
                        height={"100%"}
                        width={"100%"}
                        backgroundColor="transparent"
                    >
                        <circle cx="150" cy="86" r="5" />
                        <circle cx="194" cy="86" r="5" />
                        <circle cx="238" cy="86" r="5" />
                    </ContentLoader>
                )}
            </div>}

            {/* {calendarModalActive && (
                <div className='visit-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <Calendar onChange={(dates) => {calendarRefValue.current = dates;}} returnValue={"range"} selectRange={true} />
                    </div>
                </div>
            )} */}
			{calendarModalActive && (
                <div className='visit-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <CalendarModule setDateInfo={(dates) => calendarRefValue.current = dates} date={
							[
								{
								  startDate: calendarRefValue.current[0],
								  endDate: calendarRefValue.current[1],
								  key: 'selection'
								}
							]
						} />
                    </div>
                </div>
            )}
        </>
    );
};
