import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { io } from 'socket.io-client';

import './index.scss';

import { store } from './store'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { NavigationPanel } from './components/NavigationPanelModule/NavigationPanel';

import { MainCardModule } from './components/MainModule/MainCardModule';
import { MainPageModule } from './components/MainModule/MainPageModule';

import { NotFoundModule } from './components/NotFoundModule/NotFoundModule';

import { LogsCardModule } from './components/LogsModule/LogsCardModule';
import { LogsPageModule } from './components/LogsModule/LogsPageModule';
import { TextLogsPageModule } from './components/LogsModule/TextLogsPageModule';
import { VisitLogsModule } from './components/LogsModule/VisitLogsModule';
import { AdminLogsPageModule } from './components/LogsModule/AdminLogsPageModule';
import { ChatLogsPageModule } from './components/LogsModule/ChatLogsPageModule';
import { AllPlayersLogsPageModule } from './components/LogsModule/AllPlayersLogsPageModule';

import { AdminsCardModule } from './components/AdminsModule/AdminsCardModule';
import { AdminsPageModule } from './components/AdminsModule/AdminsPageModule';

import { SupportCardModule } from './components/SupportModule/SupportCardModule';
import { SupportPageModule } from './components/SupportModule/SupportPageModule';

import { ServerSettingsCardModule } from './components/ServerSettingsModule/ServerSettingsCardModule';
import { ServerSettingsPageModule } from './components/ServerSettingsModule/ServerSettingsPageModule';

import { ProfileBarModule } from './components/ProfileModule/ProfileBarModule';
import { ProfilePageModule } from './components/ProfileModule/ProfilePageModule';

var socketIO;
var BackEndURL;

if(process.env.NODE_ENV === "production")
{
	BackEndURL = "https://freez-host.ru:2053/";
	socketIO = io("https://freez-host.ru:2083", {
		transports: [ "websocket" ]
	});
} else {
	BackEndURL = "http://localhost:2000/";
	socketIO = io("http://localhost:4000", {
		transports: [ "websocket" ]
	});
}

export { socketIO, BackEndURL };

// NOTE: Menu card module
export const CardModule = new Map([
	['', <MainCardModule />],
	['LogsPageModule', <LogsCardModule />],
	['AdminsPageModule', <AdminsCardModule />],
	['SupportPageModule', <SupportCardModule />],
	['ServerSettingsPageModule', <ServerSettingsCardModule />],
]);

// NOTE: All pages module
const PageModule = new Map([
	['LogsPageModule', <LogsPageModule />],
	['TextLogsPageModule', <TextLogsPageModule />],
	['VisitLogsModule', <VisitLogsModule />],
	['AdminLogsPageModule', <AdminLogsPageModule />],
	['ChatLogsPageModule', <ChatLogsPageModule />],
	['AllPlayersLogsPageModule', <AllPlayersLogsPageModule />],
	['AdminsPageModule', <AdminsPageModule />],
	['SupportPageModule', <SupportPageModule />],
	['ServerSettingsPageModule', <ServerSettingsPageModule />],
	['ProfilePageModule', <ProfilePageModule />],
]);

const routes = []
PageModule.forEach((value, key) => {
	routes.push(<Route key={"/" + key} path={key} element={value} />)
})

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>

			<div className='flex-body'>
				<BrowserRouter>
					<NavigationPanel/>
					<div className='page'>
						<ProfileBarModule/>
						<div className='content'>
							<Routes>
								<Route path="/" element={<MainPageModule/>} />
								{routes}
								<Route path="/NotFound" element={<NotFoundModule/>} />
								<Route path="*" element={<Navigate to="/NotFound" replace />} />
							</Routes>
						</div>
					</div>
				</BrowserRouter>
			</div>

		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);