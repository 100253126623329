import React, { useEffect } from 'react';
import './ProfileModule.scss';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { authUser } from '../../store/auth';
import { CustomSelect } from '../../utils/CustomSelect';
import { BackEndURL } from '../..';

export const ProfileBarModule = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios.get(BackEndURL, {withCredentials: true}) // Server URL Type: http(s)://domain + "/"
        .then(res => {
            if(res.status === 200 && res.data.success) {
                dispatch(authUser(res.data.user)); // диспатч в хранилище редакса
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    function login() {
		window.location.href = BackEndURL + "authenticate";
	}

	function logout() {
		window.location.href = BackEndURL + "logout";
	}

    return (
        <>
			<div className='profile-bar'>
                <div className='profile-bar-server'>
                    {auth && <CustomSelect/>}
                </div>
                
                <div className='profile-bar-status'>
                    { auth ? (
                            <>
                                <button className='grey-button' onClick={() => logout()}>Выйти</button>
                                <span onClick={() => console.log(auth)}>{auth.username}</span>
                                <Link to={"/ProfilePageModule" + "?steamid=" + auth.steamid.toString()}><img src={auth.avatar.large} alt="Avatar"/></Link>
                            </>
                        ) : 
                        (
                            <button className='grey-button' onClick={() => login()}>Войти</button>
                        )
                    }
                </div>
            </div>
        </>
    );
};
