import React, { useRef, useState } from 'react';
import './ServerSettingsModule.scss';
import { socketIO } from '../..';
import {createRippleEffect} from '../../utils/RippleEffect.js';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { serversRefresh } from '../../store/servers';

export const ServerSettingsPageModule = () => {

	const [SearchInput, SetSearchInput] = useState("");
    const serversList = useSelector(state => state.servers);
    const dispatch = useDispatch();

    const [isDataLoading, setIsDataLoading] = useState(false);

    const lastElement = useRef();

	const [modalActive, setModalActive] = useState(false);
    const [serverModalID, setServerModalID] = useState("");
    const [serverModalName, setServerModalName] = useState("");
	const [serverModalIP, setServerModalIP] = useState("");

    const [deleteServerModalActive, setDeleteServerModalActive] = useState(false);
    const [deleteServerID, setDeleteServerID] = useState(null);
    
    const auth = useSelector(state => state.auth);

    var regex = new RegExp(/^(1|[1-9][0-9]{0,3})$/);

    function addServer() {
        socketIO.emit("InsertNewServer", JSON.stringify({"server_id": serverModalID,
                                                        "server_name": serverModalName, 
                                                        "server_ip": serverModalIP
                                                        }), (data) => {
            if(JSON.parse(data).success === 200) {
                //Refresh servers list
                let asd = (serversList.servers.length + 1).toString();
                let ser = JSON.parse(JSON.stringify(serversList));
                ser.servers = {
                    ...ser.servers, asd: {"server_id": serverModalID,
                    "server_name": serverModalName, 
                    "server_ip": serverModalIP
                    }
                };

                dispatch(serversRefresh(ser));
            }
        });
    }

    function deleteServer() {
        if(!deleteServerID) return;

        socketIO.emit("RemoveServer", JSON.stringify({"server_id": deleteServerID}), (data) => {
            if(JSON.parse(data).success === 200) {
                //Refresh servers list
                let asd = {};
                asd.servers = serversList.servers.filter((e) => {
                    return e.server_id !== deleteServerID;
                });
                
                dispatch(serversRefresh(asd));

                setDeleteServerID(null);
            }
        });
    }

	const customSort = (arr) => {
        if(arr === null) return [];

        /*arr = arr.filter(element => ((element[1].nickname !== undefined && element[1].nickname.toLowerCase().includes(SearchInput.toLowerCase())) || 
                                    (element[1].steamid !== undefined && element[1].steamid.toLowerCase().includes(SearchInput.toLowerCase())) ||
                                    (element[1].text !== undefined && element[1].text.toLowerCase().includes(SearchInput.toLowerCase()))) &&
                                    (element[1].auth !== undefined && element[1].auth.toLowerCase().includes(ur.profile.auth)) &&
                                    (Math.floor(new Date(calendarValue[0]).getTime() / 1000) <= element[1].timestamp && 
                                    element[1].timestamp <= Math.floor(new Date(calendarValue[1]).getTime() / 1000)));*/

        return [].concat(arr);
    }



    return (
        <>
        {auth && auth.permission.indexOf("o") !== -1 &&
            <div className='server-settings-block text-align-center'>
				<span className='text-align-center'>Настройка серверов</span>
				<div className='server-utils'>
					<input className='search-input' onChange={(e) => {SetSearchInput(e.target.value);}} value={SearchInput} placeholder='Поиск сервера'></input>
					<button onClick={(e) => {createRippleEffect(e); setModalActive(true);}} className='grey-button pos-rel'>Добавить сервер</button>
				</div>
				<div className='server-list'>
                    <table>
						<thead>
							<tr>
								<th>ID</th>
								<th>Имя</th>
								<th>IP</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{serversList.servers ? (customSort(Object.entries(serversList.servers)).map((data, index) => { return (
								<tr key={index}>
									{
										data[1].server_id !== 0 && <>
											<td id='id'>{data[1].server_id}</td>
											<td id='name'>{data[1].server_name}</td>
											<td id='ip'>{data[1].server_ip}</td>
											<td><button onClick={(e) => {createRippleEffect(e); setDeleteServerID(data[1].server_id); setDeleteServerModalActive(true);}} className='grey-button pos-rel'>Удалить</button></td>
										</>
									}
								</tr>
							)})) : (null)}
						</tbody>
					</table>
						{/* {serversList.servers && customSort(Object.entries(serversList.servers)).map((data, index) => { return (
							<div className='server-data' key={index}>
                                <span id='id'>{data[1].server_id}</span>
								<span id='name'>{data[1].server_name}</span>
								<span id='ip'>{data[1].server_ip}</span>

                                <button onClick={(e) => {createRippleEffect(e); setDeleteServerID(data[1].server_id); setDeleteServerModalActive(true);}} className='grey-button pos-rel'>Удалить</button>
							</div>
						)})} */}
						{!isDataLoading ?
						(<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
						(
							<ContentLoader
								viewBox="0 0 400 160"
								height={"100%"}
								width={"100%"}
								backgroundColor="transparent"
							>
								<circle cx="150" cy="86" r="5" />
								<circle cx="194" cy="86" r="5" />
								<circle cx="238" cy="86" r="5" />
							</ContentLoader>
						)}
				</div>
            </div>}

			{modalActive && (
                <div className='server-add-modal' onClick={(e) => {setModalActive(false); setServerModalName(""); setServerModalIP(""); setServerModalID("");}}>
                    <div className='server-details' onClick={(e) => {e.stopPropagation();}}>
                        <input id='id' className='server-data-input' onChange={(e) => { if(regex.test(e.target.value) || e.target.value === "") {setServerModalID(e.target.value);}}} value={serverModalID} placeholder='ID'></input>
						<input id='name' className='server-data-input' onChange={(e) => {setServerModalName(e.target.value);}} value={serverModalName} placeholder='Название сервера'></input>
						<input id='ip' className='server-data-input' onChange={(e) => {setServerModalIP(e.target.value);}} value={serverModalIP} placeholder='IP'></input>

                        <button onClick={(e) => {createRippleEffect(e); setModalActive(false); addServer(); setServerModalName(""); setServerModalIP(""); setServerModalID("");}} className='grey-button pos-rel'>Отправить</button>
                    </div>
                </div>
            )}
            {deleteServerModalActive && (
                <div className='server-delete-modal' onClick={(e) => {setDeleteServerModalActive(false);}}>
                    <div className='server-details' onClick={(e) => {e.stopPropagation();}}>
                        <span>Вы точно хотите удалить этот сервер?</span>

                        <div className='time-to-choose'>
                            <button id='yes' onClick={(e) => {createRippleEffect(e); deleteServer(); setDeleteServerModalActive(false);}} className='grey-button pos-rel'>Да</button>
                            <button id='no' onClick={(e) => {createRippleEffect(e); setDeleteServerID(null); setDeleteServerModalActive(false);}} className='grey-button pos-rel'>Нет</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
