import React from 'react';
import { useDispatch } from 'react-redux';

import { BsStackOverflow } from 'react-icons/bs'

import { setCurrentModule } from '../../store/tempData';

export const LogsCardModule = () => {

	const dispatch = useDispatch();

	return (
		<div onClick={ () => dispatch(setCurrentModule("LogsPageModule")) } className='flex-card-image text-align-center align-items-center'>
			<BsStackOverflow size={"28px"}/>
			<span>Логи</span>
		</div>
	);
};
