import React, { useState, useEffect, useRef } from 'react';
import './LogsModule.scss';
import { socketIO } from '../..';
import ContentLoader from 'react-content-loader';
// import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import { CalendarModule } from '../../utils/CalendarModule';
import HashMap from 'hashmap';

// import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';

export const ChatLogsPageModule = () => {

    const [SearchInput, SetSearchInput] = useState("");
    const [ChatLogsData, SetChatLogsData] = useState({});
	const [sidCollection, SetSidCollection] = useState(new HashMap());
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    let firstItemIndex = 0;
	// const defaultDate = [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), new Date()];
    const [isChatLoading, setIsChatLoading] = useState(true);
    const [LoadSteamData, setLoadSteamData] = useState(false);

    const lastElement = useRef();
    const firstLoad = useRef();
    const lastid = useRef();
    const observer = useRef();

    const [calendarModalActive, setCalendarModalActive] = useState(false);
    const calendarRefValue = useRef();

    const selectedServer = useSelector(state => state.servers);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        firstLoad.current = true;
		// console.log(defaultDate);

        calendarRefValue.current = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)];

        // socketIO.emit("GetChatLogs", JSON.stringify({"server_id": selectedServer.selectedServer}), (data) => {
        //     SetChatLogsData(JSON.parse(data));

        // });
    }, []);

    useEffect(() => {

		lastid.current = 0;
		SetChatLogsData({});
		setPage(1);
    }, [selectedServer.selectedServer, calendarRefValue.current]);

	// useEffect(() => {
	// 	console.log(ChatLogsData);
	// }, [ChatLogsData]);

	useEffect(() => {
		const timeOutHandler = setTimeout(() => {
			socketIO.emit("GetChatLogs", JSON.stringify({
				"server_id": selectedServer.selectedServer,
				"get": limit,
				"lastid": lastid.current,
				"search": SearchInput,
				"date_start": Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000),
				"date_end": Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)
			}), (data) => {
				// console.log(ChatLogsData);
				// const res = Object.assign({}, JSON.parse(data), JSON.parse(JSON.stringify(ChatLogsData)));
				let res = JSON.parse(data);

				// console.log(res);
				// console.log(res.error);
				// let test = res.hasOwnProperty("error");
				// console.log(res);
				// console.log(res.hasOwnProperty("error"));

				if(!res.hasOwnProperty('error'))
				{
					// Формирование массива sid_collection который содержит steamid, для которых необходимо получить информацию

					// console.log(res.hasOwnProperty("error"));

					// console.log(ChatLogsData);
					
					
					setLoadSteamData(true);

					if(res[Object.keys(res).length - 1] !== undefined)
					{
						lastid.current = res[Object.keys(res).length - 1].id;
					}
					
	
					const filtered_data = Object.entries(res);
					let sid_collection = [];
	
					filtered_data.forEach((filtered_data_value, index) => {
						if(sidCollection.has(filtered_data_value[1].auth) === false)
							sid_collection.push(filtered_data_value[1].auth);
					});
	
					if(sid_collection.length !== 0)
					{
						socketIO.emit("GetPlayersSteamInfo", JSON.stringify({"sid_collection": sid_collection}), (sid_info) => {
							sid_info = JSON.parse(sid_info);
							if(sid_info.success !== 200)
							{
								console.log("Error get SteamID's info from ChatLogsPageModule");
								return;
							}
	
							const hash_sids = new HashMap(sid_info.steam_data);
	
							let sidCollectionClone = sidCollection.clone();
	
							hash_sids.forEach((kvalue, key) => {
								sidCollectionClone.set(key, kvalue);
							});
	
							SetSidCollection(sidCollectionClone);
							SetChatLogsData(prevChatLogsData => {
								// console.log(Object.assign({}, Object.values(prevChatLogsData).concat(Object.values(res))));
								return Object.assign({}, Object.values(prevChatLogsData).concat(Object.values(res)));
							});
	
							setLoadSteamData(false);
						});
					} else {
						SetChatLogsData(prevChatLogsData => {
							return Object.assign({}, Object.values(prevChatLogsData).concat(Object.values(res)));
						});
						setLoadSteamData(false);
					}
				}
			});

		}, 250);

		return () => {
			clearTimeout(timeOutHandler);
		};
		
	}, [page, SearchInput, selectedServer.selectedServer, calendarRefValue.current]);



    useEffect(() => {
		// console.log(isChatLoading, LoadSteamData, lastElement.current);
        if(isChatLoading || LoadSteamData || lastElement.current === null || !auth) return;

        if(observer.current) {
            observer.current.disconnect();
        }
        
        var callback = function(entries, observer) {
			// console.log(ChatLogsData);
			// console.log(limit * page);
			// console.log(customSort(Object.entries(ChatLogsData)));
			// console.log((customSort(Object.entries(ChatLogsData)).length));
			// console.log(entries[0].isIntersecting);
            if(entries[0].isIntersecting && (limit * page) < (customSort(Object.entries(ChatLogsData)).length + limit)) {
                setPage(prevpage => {
					return prevpage + 1;
				});
				// console.log("test");
            }
            firstLoad.current = false;
        };
        
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastElement.current);
    }, [isChatLoading, ChatLogsData, LoadSteamData]);

	useEffect(() => {
        setIsChatLoading(true);

		const timeOutHandler = setTimeout(() =>	setIsChatLoading(false), 1000);

		return () => {
			clearTimeout(timeOutHandler);
		};
	}, [page, calendarRefValue.current]);

	// useEffect(() => {
	// 	const timeOutHandler = setTimeout(() =>	setIsChatLoading(false), 1000);
  
	// 	return () => {
	// 		clearTimeout(timeOutHandler);
	// 	};
	// }, []);
    
    useEffect(() => {
        const timeOutHandler = setTimeout(() => {
			// console.log("Page: " + page);

			lastid.current = 0;
			SetChatLogsData({});
			if(page !== 1)
			{
				setPage(1);
			}
		}, 250);
  
		return () => {
			clearTimeout(timeOutHandler);
		};

    }, [SearchInput]);

    const customSort = (arr) => {
        if(arr === null) return [];

        // arr = arr.filter(element => (Math.floor(new Date(calendarRefValue.current[0]).getTime() / 1000) <= element[1].timestamp && 
        //                             element[1].timestamp <= Math.floor(new Date(calendarRefValue.current[1]).getTime() / 1000)));

        return [].concat(arr).sort((a, b) => parseFloat(b[1].timestamp) - parseFloat(a[1].timestamp));
        //return [].concat(arr);
    }

    return (
        <>
        {auth && auth.permission.indexOf("d") !== -1 &&
            <div id='scrll' className='chat-log-block'>
                <div className="chat-utils">
                    <input className='search-input' onChange={(e) => {SetSearchInput(e.target.value);}} value={SearchInput} placeholder='Steam ID или ник игрока'></input>
                    {calendarRefValue.current &&
                        <input id='calendar' onClick={(e) => {setCalendarModalActive(true);}} value={calendarRefValue.current[0].toLocaleDateString() + " - " + calendarRefValue.current[1].toLocaleDateString()} readOnly></input>
                    }
                    <span className='count-columns'>Кол-во записей: {customSort(Object.entries(ChatLogsData)).length}</span>
                </div>
                {customSort(Object.entries(ChatLogsData)).map((data, index) => { return (
                    <div className='chat-message' key={index}>
                        <div className='chat-avatar'>
                            {sidCollection.has(data[1].auth) !== false && <>
								<Link to={"/ProfilePageModule" + "?steamid=" + sidCollection.get(data[1].auth).steamid}>
									<img src={sidCollection.get(data[1].auth).avatar} alt='Avatar'/>
								</Link>
							</>}
                            
                        </div>
                        <div className='chat-content'>
                            <div className='chat-nick'>
								<span>{sidCollection.has(data[1].auth) !== false ? sidCollection.get(data[1].auth).personaname : data[1].nickname} {new Date(data[1].timestamp * 1000).toLocaleString()} {selectedServer.servers.find(x => x.server_id === data[1].server_id) !== undefined ?
												<>{selectedServer.servers.find(x => x.server_id === data[1].server_id).server_name}</>
												:
												<>Сервер не найден</>}
								</span>
							</div>
                            <div className='chat-text'>
                                <p>{data[1].text}</p>
                            </div>
                        </div>
                    </div>
                )})}
                {!isChatLoading && !LoadSteamData ?
                (<div ref={lastElement} style={{height: "20px", background: "transparent", flexShrink: 0}}></div>) :
                (
                    <ContentLoader
                        viewBox="0 0 400 160"
                        height={"100%"}
                        width={"100%"}
                        backgroundColor="transparent"
                    >
                        <circle cx="150" cy="86" r="5" />
                        <circle cx="194" cy="86" r="5" />
                        <circle cx="238" cy="86" r="5" />
                    </ContentLoader>
                )}
            </div>}

            {/* {calendarModalActive && (
                <div className='chat-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <Calendar onChange={(dates) => {calendarRefValue.current = dates;}} returnValue={"range"} selectRange={true} />
                    </div>
                </div>
            )} */}

			{calendarModalActive && (
                <div className='chat-modal-warn' onClick={(e) => {setCalendarModalActive(false);}}>
                    <div className='calendar-details' onClick={(e) => {e.stopPropagation();}}>
                        <CalendarModule setDateInfo={(dates) => calendarRefValue.current = dates} date={
							[
								{
								  startDate: calendarRefValue.current[0],
								  endDate: calendarRefValue.current[1],
								  key: 'selection'
								}
							]
						} />
                    </div>
                </div>
            )}
        </>
    );
};
